import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Icons } from "../../../constants/Icons";
import { RoutePaths } from "../../../constants/RoutePaths";
import { AuthUserName } from "../../../consumers/auth/AuthUserName";

export const UserDropdown = () => {
  return (
    <Dropdown floating item trigger={<AuthUserName />}>
      <Dropdown.Menu>
        {/* マイページ */}
        <Dropdown.Item
          text="マイページ"
          as={Link}
          to={RoutePaths.MY_PAGE}
          icon={Icons.USER}
        />
        {/* 組織ページ */}
        <Dropdown.Item
          text="組織ページ"
          as={Link}
          to={RoutePaths.ORGANIZATION}
          icon={Icons.ORGANIZATION}
        />
        {/* ログアウト */}
        <Dropdown.Item
          text="ログアウト"
          as={Link}
          to={RoutePaths.LOGOUT}
          icon={Icons.SIGN_OUT}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
