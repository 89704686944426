import {
  TeamApiFactory,
  TeamMembershipApiFactory,
  LayerPermissionApiFactory,
  CreateTeamRequest,
  Team,
  AddTeamMemberRequest,
  User,
  PermissionType,
  Layer,
  Organization,
  LayerWithPermission,
  UpdateTeamRequest,
} from "../generated/api";
import { createConfig } from "./configuration";

export type CreateTeamParams = CreateTeamRequest;

export const fetchTeam = async (teamId: Team["id"]) => {
  const config = await createConfig();
  const res = await TeamApiFactory(config).getTeam(teamId);
  return res;
};

export const createTeam = async (params: CreateTeamParams) => {
  const config = await createConfig();

  const res = await TeamApiFactory(config).createTeam(params);
  return res;
};

export const deleteTeam = async (teamId: Team["id"]) => {
  const config = await createConfig();
  const res = await TeamApiFactory(config).deleteTeam(teamId);
  return res;
};

export type CreateTeamWithLayerPermissionsAndMembers = CreateTeamRequest & {
  layerIdsWithPermission?: {
    layerId: Layer["id"];
    permissionType: PermissionType;
  }[];
  memberIds?: User["id"][];
};

export const createTeamWithLayerPermissionsAndMembers = async (
  params: CreateTeamWithLayerPermissionsAndMembers
) => {
  // TODO: バックエンド側でまとめて行う

  const { layerIdsWithPermission, memberIds, ...teamParams } = params;

  const teamResponse = await createTeam(teamParams);
  const teamId = teamResponse.data.id;

  if (layerIdsWithPermission) {
    await updateTeamLayers(teamId, layerIdsWithPermission);
  }

  if (memberIds) {
    await updateTeamMembers(teamId, memberIds);
  }

  return teamResponse;
};

export const updateTeam = async (
  teamId: Team["id"],
  params: UpdateTeamRequest
) => {
  const config = await createConfig();

  const res = await TeamApiFactory(config).updateTeam(teamId, params);
  return res;
};

export type UpdateTeamWithLayerPermissionsAndMembers = UpdateTeamRequest & {
  layerIdsWithPermission?: {
    layerId: Layer["id"];
    permissionType: PermissionType;
  }[];
  memberIds?: User["id"][];
};

export const updateTeamWithLayerPermissionsAndMembers = async (
  teamId: Team["id"],
  params: UpdateTeamWithLayerPermissionsAndMembers
) => {
  // TODO: バックエンド側でまとめて行う

  const { layerIdsWithPermission, memberIds, ...teamParams } = params;

  const teamResponse = await updateTeam(teamId, teamParams);

  if (layerIdsWithPermission) {
    await updateTeamLayers(teamId, layerIdsWithPermission, { replace: true });
  }

  if (memberIds) {
    await updateTeamMembers(teamId, memberIds, { replace: true });
  }

  return teamResponse;
};

export const fetchTeamsFromOrganization = async (
  organizationId: Organization["id"],
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined,
  name?: string | undefined
) => {
  const config = await createConfig();
  const res = await TeamApiFactory(config).getTeamsFromOrganization(
    organizationId,
    page,
    size,
    sort,
    name
  );
  return res;
};

export const fetchTeamsFromUser = async (
  userId: User["id"],
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined
) => {
  const config = await createConfig();
  const res = await TeamMembershipApiFactory(config).getTeamsFromUser(
    userId,
    page,
    size,
    sort
  );
  return res;
};

export const fetchTeamMembers = async (
  teamId: Team["id"],
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined
) => {
  const config = await createConfig();
  const res = await TeamMembershipApiFactory(config).getMembersFromTeam(
    teamId,
    page,
    size,
    sort
  );
  return res;
};

export const fetchAllTeamMembers = async (teamId: Team["id"]) => {
  const config = await createConfig();

  const teamMembers: User[] = [];

  for (let page = 1; ; page++) {
    const {
      data: { data, pager },
    } = await TeamMembershipApiFactory(config).getMembersFromTeam(
      teamId,
      page,
      100,
      "createdAt"
    );

    teamMembers.push(...data);

    if (!pager.hasNextPage) break;
  }

  return teamMembers;
};

export const addTeamMember = async (
  teamId: Team["id"],
  params: AddTeamMemberRequest
) => {
  const config = await createConfig();
  const res = await TeamMembershipApiFactory(config).addMemberToTeam(
    teamId,
    params
  );
  return res;
};

export const deleteTeamMember = async (
  teamId: Team["id"],
  userId: User["id"]
) => {
  const config = await createConfig();
  const res = await TeamMembershipApiFactory(config).deleteMemberFromTeam(
    teamId,
    userId
  );
  return res;
};

export const updateTeamMembers = async (
  teamId: Team["id"],
  userIds: User["id"][],
  options?: { replace: boolean }
) => {
  const { replace } = options ?? {};

  const currentMembers = await fetchAllTeamMembers(teamId);

  // replaceする場合、userIdsに含まれていないものを全て消す
  if (replace) {
    for (const { id: memberId } of currentMembers) {
      if (!userIds.includes(memberId)) {
        await deleteTeamMember(teamId, memberId);
      }
    }
  }

  for (const userId of userIds) {
    if (currentMembers.every(({ id: memberId }) => memberId !== userId)) {
      await addTeamMember(teamId, { userId });
    }
  }
};

export const fetchTeamLayers = async (
  teamId: Team["id"],
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined
) => {
  const config = await createConfig();
  const res = await LayerPermissionApiFactory(config).getLayersFromTeam(
    teamId,
    page,
    size,
    sort
  );
  return res;
};

export const fetchAllTeamLayers = async (teamId: Team["id"]) => {
  const config = await createConfig();

  const layers: LayerWithPermission[] = [];

  for (let page = 1; ; page++) {
    const {
      data: { data, pager },
    } = await LayerPermissionApiFactory(config).getLayersFromTeam(
      teamId,
      page,
      100,
      "createdAt"
    );

    if (!data || !pager) break;

    layers.push(...data);

    if (!pager.hasNextPage) break;
  }

  return layers;
};

export const addTeamLayer = async (
  teamId: Team["id"],
  layerId: Layer["id"],
  permissionType: PermissionType
) => {
  const config = await createConfig();
  const res = await LayerPermissionApiFactory(config).addLayerToTeam(teamId, {
    layerId,
    type: permissionType,
  });
  return res;
};

export const deleteTeamLayer = async (
  teamId: Team["id"],
  layerId: Layer["id"]
) => {
  const config = await createConfig();
  const res = await LayerPermissionApiFactory(config).deletePermissionFromTeam(
    teamId,
    layerId
  );
  return res;
};

export const updateTeamLayer = async (
  teamId: Team["id"],
  layerId: Layer["id"],
  permissionType: PermissionType
) => {
  const config = await createConfig();
  const res = await LayerPermissionApiFactory(config).updateLayerOfTeam(
    teamId,
    layerId,
    {
      type: permissionType,
    }
  );
  return res;
};

export const updateTeamLayers = async (
  teamId: Team["id"],
  layerIdsWithPermission: {
    layerId: Layer["id"];
    permissionType: PermissionType;
  }[],
  options?: { replace: boolean }
) => {
  const { replace } = options ?? {};

  // replaceする場合、layerIdsWithPermissionに含まれていないものを全て消す
  if (replace) {
    const layers = await fetchAllTeamLayers(teamId);
    for (const { id: layerId } of layers) {
      if (
        layerIdsWithPermission.every(({ layerId: item }) => item !== layerId)
      ) {
        await deleteTeamLayer(teamId, layerId);
      }
    }
  }

  for (const { layerId, permissionType } of layerIdsWithPermission) {
    await addTeamLayer(teamId, layerId, permissionType);
  }
};
