import { useMemo, useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import { OrganizationsWithMembershipResponse, User } from "../../generated";
import {
  fetchCurrentUser,
  fetchCurrentUserOrganizations,
  fetchUser,
} from "../../repositories/user";
import {
  FetchHookSWROptions,
  FetchHookReturnValue,
  FetchHookMutate,
} from "../../types/hooks";

type FetchUserQueryParams = Record<string, never>;

type UseFetchUserType = (
  userId: User["id"] | undefined,
  params?: FetchUserQueryParams,
  options?: FetchHookSWROptions<User>,
  enable?: boolean
) => FetchHookReturnValue<User>;

export const useFetchUser: UseFetchUserType = (
  userId,
  params,
  options,
  enable = true
) => {
  const { mutate } = useSWRConfig();
  const key = useMemo(() => (userId ? ["fetchUser", userId] : null), [userId]);
  const mutateWithKey: FetchHookMutate<User> = useCallback(
    (...args) => mutate(key, ...args),
    [key, mutate]
  );

  const swrResponse = useSWR(
    enable ? key : null,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchUser(userId!),
    options
  );

  return { ...swrResponse, mutate: mutateWithKey };
};

type UseFetchCurrentUserType = (
  params?: FetchUserQueryParams,
  options?: FetchHookSWROptions<User>,
  enable?: boolean
) => FetchHookReturnValue<User>;
export const useFetchCurrentUser: UseFetchCurrentUserType = (
  params,
  options,
  enable = true
) => {
  const { mutate } = useSWRConfig();
  const key = "fetchCurrentUser";
  const mutateWithKey: FetchHookMutate<User> = useCallback(
    (...args) => mutate(key, ...args),
    [key, mutate]
  );

  const swrResponse = useSWR(
    enable ? key : null,
    () => fetchCurrentUser(),
    options
  );

  return { ...swrResponse, mutate: mutateWithKey };
};

type FetchCurrentUserOrganizationsQueryParams = {
  page?: number;
  size?: number;
  sort?: string;
};

type UseFetchCurrentUserOrganizationsType = (
  params?: FetchCurrentUserOrganizationsQueryParams,
  options?: FetchHookSWROptions<OrganizationsWithMembershipResponse>,
  enable?: boolean
) => FetchHookReturnValue<OrganizationsWithMembershipResponse>;

export const useFetchCurrentUserOrganizations: UseFetchCurrentUserOrganizationsType =
  (params, options, enable = true) => {
    const { mutate } = useSWRConfig();
    const { page, size, sort } = params || {};
    const key = useMemo(
      () => ["fetchCurrentUserOrganizations", page, size, sort],
      [page, size, sort]
    );
    const mutateWithKey: FetchHookMutate<OrganizationsWithMembershipResponse> =
      useCallback((...args) => mutate(key, ...args), [key, mutate]);

    const swrResponse = useSWR(
      enable ? key : null,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => fetchCurrentUserOrganizations(page, size, sort),
      options
    );

    return { ...swrResponse, mutate: mutateWithKey };
  };
