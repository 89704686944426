export const RoutePaths = {
  CHANGE_PASSWORD_OLD: "/old/profile/change_password",
  LOGIN: "/login",
  LOGOUT: "/logout",
  MAP: "/map",
  MY_PAGE: "/my_page",
  MY_PAGE_OLD: "/old/my_page",
  MY_PAGE_SAMPLE: "/sample/my_page",
  ORGANIZATION: "/organization",
  ORGANIZATION_OLD: "/old/organization",
  ORGANIZATION_SAMPLE: "/sample/organization",
  PROFILE_EDIT_OLD: "/old/profile/edit",
  ROOT: "/",
  SIGN_UP: "/sign_up",
  TEAM_OLD: "/old/team/:teamId",
  USER_NAME_EDIT_OLD: "/old/profile/edit_user_name",
};
