import {
  MyUserResponse,
  Organization,
  OrganizationMember,
  OrganizationWithMembership,
  PermissionType,
  User,
  UserRole,
} from "../generated/api";
import { hasOwnProperty } from "./object";

const ORGANIZATION_ID_PATTERN = /^or[2-7A-Z]{16}$/;
const USER_ID_PATTERN = /^us[2-7A-Z]{16}$/;

export const isOrganization = (data: unknown): data is Organization => {
  if (typeof data !== "object" || data === null || Array.isArray(data))
    return false;

  const hasValidId =
    hasOwnProperty(data, "id") &&
    typeof data.id === "string" &&
    ORGANIZATION_ID_PATTERN.test(data.id);

  return hasValidId && hasOwnProperty(data, "name");
};

export const isOrganizationWithMembership = (
  data: unknown
): data is OrganizationWithMembership => {
  if (typeof data !== "object" || data === null || Array.isArray(data))
    return false;

  const hasValidId =
    hasOwnProperty(data, "id") &&
    typeof data.id === "string" &&
    ORGANIZATION_ID_PATTERN.test(data.id);

  return (
    hasValidId &&
    hasOwnProperty(data, "name") &&
    hasOwnProperty(data, "membership")
  );
};

export const isUser = (
  data: unknown
): data is User | OrganizationMember | MyUserResponse => {
  if (typeof data !== "object" || data === null || Array.isArray(data))
    return false;

  const hasValidId =
    hasOwnProperty(data, "id") &&
    typeof data.id === "string" &&
    USER_ID_PATTERN.test(data.id);

  return hasValidId && hasOwnProperty(data, "name");
};

export const isMyUserResponse = (data: unknown): data is MyUserResponse => {
  return isUser(data) && hasOwnProperty(data, "email");
};

export const isUserRole = (data: unknown): data is UserRole => {
  return Object.values(UserRole).includes(data as UserRole);
};

export const isPermissionType = (data: unknown): data is PermissionType => {
  return Object.values(PermissionType).includes(data as PermissionType);
};
