const save = <T>(key: string, value: T) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
};

const restore = <T>(key: string, initialValue?: T) => {
  try {
    const json = localStorage.getItem(key);
    if (json) {
      return JSON.parse(json);
    } else {
      return initialValue;
    }
  } catch (e) {
    return initialValue;
  }
};

export { save, restore };
