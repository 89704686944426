import { useAuth } from "../../hooks/contexts/AuthContext";
import { UserProvider } from "../../hooks/contexts/UserContext";
import { UserName } from "../user/UserName";

type Props = {
  className?: string;
};
export const AuthUserName = (props: Props) => {
  const { className } = props;
  const [auth] = useAuth();

  if (!auth) return <></>;

  return (
    <UserProvider value={auth}>
      <UserName className={className} />
    </UserProvider>
  );
};
