export const ErrorStatusCodeMessage: { [key: number]: string | undefined } = {
  400: "不正なリクエストです",
  401: "認証が必要です",
  402: "支払いが必要です",
  403: "権限がありません",
  404: "対象が見つかりません",
  405: "許可されていない操作です",
  406: "受理できない内容です",
  408: "リクエストがタイムアウトしました",
  413: "データが大きすぎます",
  429: "一定時間内でのリクエスト数が上限を超えました",
  500: "サーバー内部エラー",
};
