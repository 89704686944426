/* tslint:disable */
/* eslint-disable */
/**
 * Hec-Eye API Document
 * # はじめに このドキュメントはアルファ版です。 今後APIの仕様が変更される場合があります。 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 組織へのユーザー追加リクエスト
 * @export
 * @interface AddOrganizationMemberRequest
 */
export interface AddOrganizationMemberRequest {
    /**
     * ユーザーID
     * @type {string}
     * @memberof AddOrganizationMemberRequest
     */
    userId: string;
    /**
     * 
     * @type {UserRole}
     * @memberof AddOrganizationMemberRequest
     */
    role?: UserRole | null;
}
/**
 * アクセス権限追加リクエスト
 * @export
 * @interface AddPermissionRequest
 */
export interface AddPermissionRequest {
    /**
     * レイヤーID
     * @type {string}
     * @memberof AddPermissionRequest
     */
    layerId: string;
    /**
     * 
     * @type {PermissionType}
     * @memberof AddPermissionRequest
     */
    type?: PermissionType | null;
}
/**
 * チームへのユーザー追加リクエスト
 * @export
 * @interface AddTeamMemberRequest
 */
export interface AddTeamMemberRequest {
    /**
     * ユーザーID
     * @type {string}
     * @memberof AddTeamMemberRequest
     */
    userId: string;
}
/**
 * 地図範囲
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 南緯
     * @type {number}
     * @memberof BoundingBox
     */
    south: number;
    /**
     * 北緯
     * @type {number}
     * @memberof BoundingBox
     */
    north: number;
    /**
     * 西経
     * @type {number}
     * @memberof BoundingBox
     */
    west: number;
    /**
     * 東経
     * @type {number}
     * @memberof BoundingBox
     */
    east: number;
}
/**
 * イベントの作成リクエスト
 * @export
 * @interface CreateEventRequest
 */
export interface CreateEventRequest {
    /**
     * このイベントの添付先イベントのID。 添付先イベントは同じインシデント内に限られる 
     * @type {string}
     * @memberof CreateEventRequest
     */
    attachTo?: string;
    /**
     * 
     * @type {EventType}
     * @memberof CreateEventRequest
     */
    type: EventType;
    /**
     * 
     * @type {EventContent}
     * @memberof CreateEventRequest
     */
    content?: EventContent | null;
}
/**
 * ファイル作成リクエスト
 * @export
 * @interface CreateFileRequest
 */
export interface CreateFileRequest {
    /**
     * ファイルアップロード用チケットID
     * @type {string}
     * @memberof CreateFileRequest
     */
    ticketId: string;
}
/**
 * ファイルアップロード用チケット発行リクエスト
 * @export
 * @interface CreateFileTicketRequest
 */
export interface CreateFileTicketRequest {
    /**
     * ファイル名
     * @type {string}
     * @memberof CreateFileTicketRequest
     */
    name: string;
    /**
     * ファイルのMD5ハッシュをBase64エンコードしたもの
     * @type {string}
     * @memberof CreateFileTicketRequest
     */
    md5Base64Digest: string;
    /**
     * ファイルのアップロード時に指定するContent-Type
     * @type {string}
     * @memberof CreateFileTicketRequest
     */
    contentType?: string | null;
}
/**
 * ファイルアップロード用チケット発行リクエスト
 * @export
 * @interface CreateFileTicketRequestWithOrgId
 */
export interface CreateFileTicketRequestWithOrgId {
    /**
     * 属す組織のID
     * @type {string}
     * @memberof CreateFileTicketRequestWithOrgId
     */
    organizationId: string;
    /**
     * ファイル名
     * @type {string}
     * @memberof CreateFileTicketRequestWithOrgId
     */
    name: string;
    /**
     * ファイルのMD5ハッシュをBase64エンコードしたもの
     * @type {string}
     * @memberof CreateFileTicketRequestWithOrgId
     */
    md5Base64Digest: string;
    /**
     * ファイルのアップロード時に指定するContent-Type
     * @type {string}
     * @memberof CreateFileTicketRequestWithOrgId
     */
    contentType?: string | null;
}
/**
 * インシデント作成リクエスト
 * @export
 * @interface CreateIncidentRequest
 */
export interface CreateIncidentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateIncidentRequest
     */
    description?: string | null;
    /**
     * 
     * @type {RequestFeatureCore}
     * @memberof CreateIncidentRequest
     */
    feature?: RequestFeatureCore | null;
}
/**
 * レイヤー作成リクエスト
 * @export
 * @interface CreateLayerRequest
 */
export interface CreateLayerRequest {
    /**
     * 属す組織のID
     * @type {string}
     * @memberof CreateLayerRequest
     */
    organizationId: string;
    /**
     * 名前
     * @type {string}
     * @memberof CreateLayerRequest
     */
    name: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof CreateLayerRequest
     */
    description?: string | null;
    /**
     * 公開レイヤーか
     * @type {boolean}
     * @memberof CreateLayerRequest
     */
    isPublic?: boolean | null;
}
/**
 * 組織作成リクエスト
 * @export
 * @interface CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * 名前。 他の組織と被らないものでなければならない 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    description?: string | null;
    /**
     * trueの場合、デフォルトセットが作成される
     * @type {boolean}
     * @memberof CreateOrganizationRequest
     */
    createDefaultSet?: boolean;
}
/**
 * チーム作成リクエスト
 * @export
 * @interface CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * 属す組織のID
     * @type {string}
     * @memberof CreateTeamRequest
     */
    organizationId: string;
    /**
     * 名前
     * @type {string}
     * @memberof CreateTeamRequest
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof CreateTeamRequest
     */
    description?: string | null;
}
/**
 * ユーザー作成リクエスト
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * ログインユーザー名。 他のユーザーと被らないものでなければならない 
     * @type {string}
     * @memberof CreateUserRequest
     */
    name: string | null;
    /**
     * 表示名。 他のユーザーと被らない方が良い 
     * @type {string}
     * @memberof CreateUserRequest
     */
    displayName?: string | null;
    /**
     * Cognitoユーザーの平文のパスワード
     * @type {string}
     * @memberof CreateUserRequest
     */
    password: string;
    /**
     * Emailアドレス
     * @type {string}
     * @memberof CreateUserRequest
     */
    email: string;
}
/**
 * タイムラインへの表示や地図上のアイコン変更など、なんらかの出来事
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * イベントID
     * @type {string}
     * @memberof Event
     */
    id: string;
    /**
     * 属すレイヤーのID
     * @type {string}
     * @memberof Event
     */
    layerId: string;
    /**
     * 属すインシデントのID
     * @type {string}
     * @memberof Event
     */
    incidentId: string;
    /**
     * このイベントの添付先イベントのID。 添付先イベントは同じインシデント内に限られる 
     * @type {string}
     * @memberof Event
     */
    attachTo?: string;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    type: EventType;
    /**
     * 
     * @type {EventContent}
     * @memberof Event
     */
    content?: EventContent | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof Event
     */
    createdAt: string;
    /**
     * 作成者のID。 作成者は主にユーザーを想定するが、システムが自動作成するような場合はレイヤーやインシデント等もありうる。 
     * @type {string}
     * @memberof Event
     */
    createdBy: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof Event
     */
    updatedAt: string;
    /**
     * 更新者のID（最初は作成者のID）
     * @type {string}
     * @memberof Event
     */
    updatedBy: string;
}
/**
 * @type EventContent
 * イベントの内容
 * @export
 */
export type EventContent = EventContentForComment | EventContentForFile | EventContentForImage | EventContentForNotification | EventContentForSetFeature | EventContentForSetTitle | EventContentForStamp | EventContentForVideo;

/**
 * コメント投稿イベントの内容
 * @export
 * @interface EventContentForComment
 */
export interface EventContentForComment {
    /**
     * テキスト形式の内容
     * @type {string}
     * @memberof EventContentForComment
     */
    text: string;
    /**
     * 
     * @type {EventLocation}
     * @memberof EventContentForComment
     */
    location?: EventLocation | null;
}
/**
 * ファイル投稿イベントの内容
 * @export
 * @interface EventContentForFile
 */
export interface EventContentForFile {
    /**
     * ファイルのID
     * @type {string}
     * @memberof EventContentForFile
     */
    fileId: string;
    /**
     * 
     * @type {EventLocation}
     * @memberof EventContentForFile
     */
    location?: EventLocation | null;
}
/**
 * 画像投稿イベントの内容
 * @export
 * @interface EventContentForImage
 */
export interface EventContentForImage {
    /**
     * ファイルのID
     * @type {string}
     * @memberof EventContentForImage
     */
    fileId: string;
    /**
     * 
     * @type {EventLocation}
     * @memberof EventContentForImage
     */
    location?: EventLocation | null;
}
/**
 * 通知投稿イベントの内容
 * @export
 * @interface EventContentForNotification
 */
export interface EventContentForNotification {
    /**
     * テキスト形式の内容
     * @type {string}
     * @memberof EventContentForNotification
     */
    text: string;
}
/**
 * 地図表示変更イベントの内容
 * @export
 * @interface EventContentForSetFeature
 */
export interface EventContentForSetFeature {
    /**
     * 
     * @type {FeatureType}
     * @memberof EventContentForSetFeature
     */
    type: FeatureType;
    /**
     * 
     * @type {Geometry}
     * @memberof EventContentForSetFeature
     */
    geometry?: Geometry | null;
    /**
     * 
     * @type {FeatureProperty}
     * @memberof EventContentForSetFeature
     */
    properties?: FeatureProperty | null;
}
/**
 * タイトル更新イベントの内容
 * @export
 * @interface EventContentForSetTitle
 */
export interface EventContentForSetTitle {
    /**
     * 名前
     * @type {string}
     * @memberof EventContentForSetTitle
     */
    name?: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof EventContentForSetTitle
     */
    description?: string | null;
}
/**
 * スタンプ投稿イベントの内容
 * @export
 * @interface EventContentForStamp
 */
export interface EventContentForStamp {
    /**
     * スタンプのコード
     * @type {string}
     * @memberof EventContentForStamp
     */
    stampId: string;
    /**
     * 
     * @type {EventLocation}
     * @memberof EventContentForStamp
     */
    location?: EventLocation | null;
}
/**
 * 動画投稿イベントの内容
 * @export
 * @interface EventContentForVideo
 */
export interface EventContentForVideo {
    /**
     * ファイルのID
     * @type {string}
     * @memberof EventContentForVideo
     */
    fileId: string;
    /**
     * 
     * @type {EventLocation}
     * @memberof EventContentForVideo
     */
    location?: EventLocation | null;
}
/**
 * イベントを補足する位置情報（イベントの発信位置等）。 インシデントの位置は変わらない。 インシデントの位置が変わるのは`set_feature`イベント 
 * @export
 * @interface EventLocation
 */
export interface EventLocation {
    /**
     * 緯度
     * @type {number}
     * @memberof EventLocation
     */
    latitude: number;
    /**
     * 経度
     * @type {number}
     * @memberof EventLocation
     */
    longitude: number;
    /**
     * m単位の高さ
     * @type {number}
     * @memberof EventLocation
     */
    altitude?: number | null;
    /**
     * m単位の精度
     * @type {number}
     * @memberof EventLocation
     */
    accuracy?: number | null;
}
/**
 * イベントの種類:  * `comment` - コメントの投稿  * `stamp` - スタンプ（LINEのスタンプを想像してもらえれば問題無い）の投稿  * `notification` - 通知メッセージの投稿  * `image` - 画像の投稿  * `video` - 動画の投稿  * `file` - ファイルの投稿  * `set_title` - タイトルの変更  * `set_feature` - 地図での位置・見た目の変更  * `close` - インシデントの終了  * `reopen` - インシデントの再開 
 * @export
 * @enum {string}
 */

export enum EventType {
    Comment = 'comment',
    Stamp = 'stamp',
    Notification = 'notification',
    Image = 'image',
    Video = 'video',
    File = 'file',
    SetTitle = 'set_title',
    SetFeature = 'set_feature',
    Close = 'close',
    Reopen = 'reopen'
}

/**
 * 
 * @export
 * @interface EventsResponse
 */
export interface EventsResponse {
    /**
     * 
     * @type {Pager}
     * @memberof EventsResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<Event>}
     * @memberof EventsResponse
     */
    data: Array<Event>;
}
/**
 * インシデントを地図上に表示するためのデータ。 GeoJSONのFeatureをベースにしている。 [参考](https://www.esrij.com/gis-guide/arcgis-basic/feature-featureclass/) 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {FeatureType}
     * @memberof Feature
     */
    type: FeatureType;
    /**
     * 
     * @type {Geometry}
     * @memberof Feature
     */
    geometry: Geometry;
    /**
     * 
     * @type {FeatureProperty}
     * @memberof Feature
     */
    properties: FeatureProperty;
    /**
     * フィーチャID
     * @type {string}
     * @memberof Feature
     */
    id: string;
    /**
     * 属すレイヤーのID
     * @type {string}
     * @memberof Feature
     */
    layerId: string;
    /**
     * 元になるインシデントのID
     * @type {string}
     * @memberof Feature
     */
    incidentId: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof Feature
     */
    createdAt: string;
    /**
     * このフィーチャがつくられるきっかけになったイベントのID
     * @type {string}
     * @memberof Feature
     */
    createdBy?: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof Feature
     */
    updatedAt?: string;
    /**
     * 元になったインシデントにとってこのフィーチャが最新である間に、インシデントに追加された最新のイベントのID（最初はcreatedByと同一）
     * @type {string}
     * @memberof Feature
     */
    updatedBy?: string;
    /**
     * 元になったインシデントにとってこのフィーチャが最新である期限。 インシデントに新しいフィーチャができたらその作成日時が設定される。 また、インシデントが閉じられたときもその日時が設定される（以後再開するまでインシデントにとって最新のフィーチャは無い状態になる） 
     * @type {string}
     * @memberof Feature
     */
    latestUntil?: string | null;
}
/**
 * 地図に表示されるデータ
 * @export
 * @interface FeatureCore
 */
export interface FeatureCore {
    /**
     * 
     * @type {FeatureType}
     * @memberof FeatureCore
     */
    type: FeatureType;
    /**
     * 
     * @type {Geometry}
     * @memberof FeatureCore
     */
    geometry: Geometry;
    /**
     * 
     * @type {FeatureProperty}
     * @memberof FeatureCore
     */
    properties: FeatureProperty;
}
/**
 * 補助データ
 * @export
 * @interface FeatureInfo
 */
export interface FeatureInfo {
    /**
     * フィーチャID
     * @type {string}
     * @memberof FeatureInfo
     */
    id?: string;
    /**
     * 属すレイヤーのID
     * @type {string}
     * @memberof FeatureInfo
     */
    layerId?: string;
    /**
     * 元になるインシデントのID
     * @type {string}
     * @memberof FeatureInfo
     */
    incidentId?: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof FeatureInfo
     */
    createdAt?: string;
    /**
     * このフィーチャがつくられるきっかけになったイベントのID
     * @type {string}
     * @memberof FeatureInfo
     */
    createdBy?: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof FeatureInfo
     */
    updatedAt?: string;
    /**
     * 元になったインシデントにとってこのフィーチャが最新である間に、インシデントに追加された最新のイベントのID（最初はcreatedByと同一）
     * @type {string}
     * @memberof FeatureInfo
     */
    updatedBy?: string;
    /**
     * 元になったインシデントにとってこのフィーチャが最新である期限。 インシデントに新しいフィーチャができたらその作成日時が設定される。 また、インシデントが閉じられたときもその日時が設定される（以後再開するまでインシデントにとって最新のフィーチャは無い状態になる） 
     * @type {string}
     * @memberof FeatureInfo
     */
    latestUntil?: string | null;
}
/**
 * GeoJSONのFeature由来。 [simplestyle](https://github.com/mapbox/simplestyle-spec/tree/master/1.1.0)を参考に画像や向きなどのプロパティを拡張したもの 
 * @export
 * @interface FeatureProperty
 */
export interface FeatureProperty {
    /**
     * 元になるインシデントの名前
     * @type {string}
     * @memberof FeatureProperty
     */
    title?: string | null;
    /**
     * 元になるインシデントの説明
     * @type {string}
     * @memberof FeatureProperty
     */
    description?: string | null;
    /**
     * 
     * @type {FeaturePropertyMarkerSize}
     * @memberof FeatureProperty
     */
    markerSize?: FeaturePropertyMarkerSize | null;
    /**
     * マーカーのアイコン
     * @type {string}
     * @memberof FeatureProperty
     */
    markerSymbol?: string | null;
    /**
     * マーカーの色
     * @type {string}
     * @memberof FeatureProperty
     */
    markerColor?: string | null;
    /**
     * 図形の辺の色
     * @type {string}
     * @memberof FeatureProperty
     */
    stroke?: string | null;
    /**
     * 図形の辺の不透明度
     * @type {number}
     * @memberof FeatureProperty
     */
    strokeOpacity?: number | null;
    /**
     * 図形の辺の太さ
     * @type {number}
     * @memberof FeatureProperty
     */
    strokeWidth?: number | null;
    /**
     * 図形の色
     * @type {string}
     * @memberof FeatureProperty
     */
    fill?: string | null;
    /**
     * 図形の不透明度
     * @type {number}
     * @memberof FeatureProperty
     */
    fillOpacity?: number | null;
    /**
     * デバイスやカメラの向きを角度で表現したもの。先頭の要素がデバイス、2番目以降はカメラの向き
     * @type {Array<number>}
     * @memberof FeatureProperty
     */
    directions?: Array<number> | null;
    /**
     * LineStringの点の名前の配列
     * @type {Array<string>}
     * @memberof FeatureProperty
     */
    waypointNames?: Array<string> | null;
    /**
     * アイコンに画像を使用する場合、そのURL
     * @type {string}
     * @memberof FeatureProperty
     */
    icon?: string | null;
    /**
     * 
     * @type {FeaturePropertyPattern}
     * @memberof FeatureProperty
     */
    pattern?: FeaturePropertyPattern | null;
}
/**
 * マーカーのサイズ
 * @export
 * @enum {string}
 */

export enum FeaturePropertyMarkerSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large'
}

/**
 * 図形の塗り潰しパターン
 * @export
 * @enum {string}
 */

export enum FeaturePropertyPattern {
    Stripe = 'stripe'
}

/**
 * GeoJSONのFeature由来。Featureで固定
 * @export
 * @enum {string}
 */

export enum FeatureType {
    Feature = 'Feature'
}

/**
 * 
 * @export
 * @interface FeaturesResponse
 */
export interface FeaturesResponse {
    /**
     * 
     * @type {Pager}
     * @memberof FeaturesResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof FeaturesResponse
     */
    data: Array<Feature>;
}
/**
 * ファイルアップロード用チケット
 * @export
 * @interface FileTicket
 */
export interface FileTicket {
    /**
     * ファイルアップロード用チケットID
     * @type {string}
     * @memberof FileTicket
     */
    id: string;
    /**
     * ファイルのアップロード用URL
     * @type {string}
     * @memberof FileTicket
     */
    uploadUrl: string;
    /**
     * 有効期限
     * @type {string}
     * @memberof FileTicket
     */
    expiredAt: string;
}
/**
 * 指定した範囲の日時のファイルストレージの使用量
 * @export
 * @interface FilesMetricsResponse
 */
export interface FilesMetricsResponse {
    /**
     * 総使用バイト数
     * @type {number}
     * @memberof FilesMetricsResponse
     */
    totalBytes: number;
    /**
     * 集計開始日時
     * @type {string}
     * @memberof FilesMetricsResponse
     */
    startTime: string;
    /**
     * 集計終了日時
     * @type {string}
     * @memberof FilesMetricsResponse
     */
    endTime: string;
}
/**
 * 
 * @export
 * @interface FilesResponse
 */
export interface FilesResponse {
    /**
     * 
     * @type {Pager}
     * @memberof FilesResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<any>}
     * @memberof FilesResponse
     */
    data: Array<any>;
}
/**
 * GeoJSONのFeature由来。地図上に描画する図形
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * 
     * @type {GeometryType}
     * @memberof Geometry
     */
    type: GeometryType;
    /**
     * 
     * @type {Array<any>}
     * @memberof Geometry
     */
    coordinates: Array<any>;
}
/**
 * goの生成コードのcoordinatesの型を空インターフェースの配列にするため
 * @export
 * @interface GeometryAllOf
 */
export interface GeometryAllOf {
    /**
     * 
     * @type {Array<any>}
     * @memberof GeometryAllOf
     */
    coordinates?: Array<any>;
}
/**
 * @type GeometryCore
 * GeoJSONのFeature由来。地図上に描画する図形
 * @export
 */
export type GeometryCore = GeometryForLineString | GeometryForPoint | GeometryForPolygon;

/**
 * 一本の線を表現する
 * @export
 * @interface GeometryForLineString
 */
export interface GeometryForLineString {
    /**
     * 
     * @type {GeometryType}
     * @memberof GeometryForLineString
     */
    type: GeometryType;
    /**
     * LineStringの位置情報（Pointの位置情報の配列になる）
     * @type {Array<Array<number>>}
     * @memberof GeometryForLineString
     */
    coordinates: Array<Array<number>>;
}
/**
 * 一つの点を表現する
 * @export
 * @interface GeometryForPoint
 */
export interface GeometryForPoint {
    /**
     * 
     * @type {GeometryType}
     * @memberof GeometryForPoint
     */
    type: GeometryType;
    /**
     * Pointの位置情報。 配列の先頭2つの要素に経度、緯度が入り、3つ目の要素がある場合には高度が入っている 
     * @type {Array<number>}
     * @memberof GeometryForPoint
     */
    coordinates: Array<number>;
}
/**
 * 1つの多角形を表現する
 * @export
 * @interface GeometryForPolygon
 */
export interface GeometryForPolygon {
    /**
     * 
     * @type {GeometryType}
     * @memberof GeometryForPolygon
     */
    type: GeometryType;
    /**
     * Polygonの位置情報（LineStringの位置情報の配列になる）
     * @type {Array<Array<Array<number>>>}
     * @memberof GeometryForPolygon
     */
    coordinates: Array<Array<Array<number>>>;
}
/**
 * 図形の種類:  * `Point` - 一つの点  * `LineString` - 一本の線  * `Polygon` - 1つの多角形 
 * @export
 * @enum {string}
 */

export enum GeometryType {
    Point = 'Point',
    LineString = 'LineString',
    Polygon = 'Polygon'
}

/**
 * 関連する一連のイベントをまとめた事象を表す概念
 * @export
 * @interface Incident
 */
export interface Incident {
    /**
     * インシデントID
     * @type {string}
     * @memberof Incident
     */
    id: string;
    /**
     * 属すレイヤーのID
     * @type {string}
     * @memberof Incident
     */
    layerId: string;
    /**
     * 最初のイベントのID
     * @type {string}
     * @memberof Incident
     */
    firstEventId?: string;
    /**
     * 名前
     * @type {string}
     * @memberof Incident
     */
    name?: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof Incident
     */
    description?: string | null;
    /**
     * 事象が継続しているとみなされているか。 trueの間はイベントに従いfeatureプロパティを元にフィーチャがつくられる。 インシデントを閉じるとfalseになり、その時点で最新のフィーチャのlatestUntilをその日時に設定する。 falseの間はフィーチャはつくらない。 インシデントを開き直すとtrueになり、その時点のfeatureプロパティからフィーチャをつくる。 
     * @type {boolean}
     * @memberof Incident
     */
    isOpen: boolean | null;
    /**
     * 
     * @type {FeatureCore}
     * @memberof Incident
     */
    feature?: FeatureCore | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof Incident
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof Incident
     */
    updatedAt: string;
    /**
     * 最新のイベントのID
     * @type {string}
     * @memberof Incident
     */
    updatedBy: string;
}
/**
 * 
 * @export
 * @interface IncidentsResponse
 */
export interface IncidentsResponse {
    /**
     * 
     * @type {Pager}
     * @memberof IncidentsResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<Incident>}
     * @memberof IncidentsResponse
     */
    data: Array<Incident>;
}
/**
 * インシデントを載せるレイヤー
 * @export
 * @interface Layer
 */
export interface Layer {
    /**
     * レイヤーID
     * @type {string}
     * @memberof Layer
     */
    id: string;
    /**
     * 属す組織のID
     * @type {string}
     * @memberof Layer
     */
    organizationId: string;
    /**
     * 名前
     * @type {string}
     * @memberof Layer
     */
    name: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof Layer
     */
    description?: string | null;
    /**
     * 公開レイヤーか
     * @type {boolean}
     * @memberof Layer
     */
    isPublic: boolean | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof Layer
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof Layer
     */
    updatedAt: string;
}
/**
 * アクセスできるレイヤー
 * @export
 * @interface LayerWithPermission
 */
export interface LayerWithPermission {
    /**
     * レイヤーID
     * @type {string}
     * @memberof LayerWithPermission
     */
    id: string;
    /**
     * 属す組織のID
     * @type {string}
     * @memberof LayerWithPermission
     */
    organizationId: string;
    /**
     * 名前
     * @type {string}
     * @memberof LayerWithPermission
     */
    name: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof LayerWithPermission
     */
    description?: string | null;
    /**
     * 公開レイヤーか
     * @type {boolean}
     * @memberof LayerWithPermission
     */
    isPublic: boolean | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof LayerWithPermission
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof LayerWithPermission
     */
    updatedAt: string;
    /**
     * 
     * @type {Permission}
     * @memberof LayerWithPermission
     */
    permission: Permission;
}
/**
 * 
 * @export
 * @interface LayerWithPermissionAllOf
 */
export interface LayerWithPermissionAllOf {
    /**
     * 
     * @type {Permission}
     * @memberof LayerWithPermissionAllOf
     */
    permission?: Permission;
}
/**
 * 
 * @export
 * @interface LayersMetricsResponse
 */
export interface LayersMetricsResponse {
    /**
     * レイヤー作成数
     * @type {number}
     * @memberof LayersMetricsResponse
     */
    layerCreateCount: number;
    /**
     * 
     * @type {string}
     * @memberof LayersMetricsResponse
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof LayersMetricsResponse
     */
    endTime: string;
}
/**
 * 
 * @export
 * @interface LayersResponse
 */
export interface LayersResponse {
    /**
     * 
     * @type {Pager}
     * @memberof LayersResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<Layer>}
     * @memberof LayersResponse
     */
    data: Array<Layer>;
}
/**
 * 
 * @export
 * @interface LayersWithPermissionResponse
 */
export interface LayersWithPermissionResponse {
    /**
     * 
     * @type {Pager}
     * @memberof LayersWithPermissionResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<LayerWithPermission>}
     * @memberof LayersWithPermissionResponse
     */
    data: Array<LayerWithPermission>;
}
/**
 * ファイル。 組織のリソースとしてつくられた場合はorganizationId、レイヤーのリソースとしてつくられた場合はlayerIdが設定される 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * ファイルID
     * @type {string}
     * @memberof ModelFile
     */
    id: string;
    /**
     * 属す組織のID
     * @type {string}
     * @memberof ModelFile
     */
    organizationId?: string;
    /**
     * 属すレイヤーのID
     * @type {string}
     * @memberof ModelFile
     */
    layerId?: string;
    /**
     * ファイル名
     * @type {string}
     * @memberof ModelFile
     */
    name: string;
    /**
     * ファイルの配信URL
     * @type {string}
     * @memberof ModelFile
     */
    downloadUrl: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof ModelFile
     */
    createdAt: string;
    /**
     * 作成者のID
     * @type {string}
     * @memberof ModelFile
     */
    createdBy: string;
}
/**
 * ユーザー自身の情報
 * @export
 * @interface MyUserResponse
 */
export interface MyUserResponse {
    /**
     * ユーザーID
     * @type {string}
     * @memberof MyUserResponse
     */
    id: string;
    /**
     * ログインユーザー名。 他のユーザーと被らないものでなければならない 
     * @type {string}
     * @memberof MyUserResponse
     */
    name: string | null;
    /**
     * 表示名。 他のユーザーと被らない方が良い 
     * @type {string}
     * @memberof MyUserResponse
     */
    displayName?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof MyUserResponse
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof MyUserResponse
     */
    updatedAt: string;
    /**
     * Emailアドレス
     * @type {string}
     * @memberof MyUserResponse
     */
    email: string;
}
/**
 * 
 * @export
 * @interface MyUserResponseAllOf
 */
export interface MyUserResponseAllOf {
    /**
     * Emailアドレス
     * @type {string}
     * @memberof MyUserResponseAllOf
     */
    email: string;
}
/**
 * 組織。 ユーザーが所属し、レイヤーやチームといったリソースを持つ。 課金するときの請求先にもなる想定 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 組織ID
     * @type {string}
     * @memberof Organization
     */
    id: string;
    /**
     * 名前。 他の組織と被らないものでなければならない 
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof Organization
     */
    description?: string | null;
    /**
     * 組織を作成したユーザーのユーザーID
     * @type {string}
     * @memberof Organization
     */
    createdBy: string;
    /**
     * 組織が無効化されているか
     * @type {boolean}
     * @memberof Organization
     */
    deactivated?: boolean;
    /**
     * 作成日時
     * @type {string}
     * @memberof Organization
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof Organization
     */
    updatedAt: string;
}
/**
 * 組織に所属するユーザー
 * @export
 * @interface OrganizationMember
 */
export interface OrganizationMember {
    /**
     * ユーザーID
     * @type {string}
     * @memberof OrganizationMember
     */
    id: string;
    /**
     * ログインユーザー名。 他のユーザーと被らないものでなければならない 
     * @type {string}
     * @memberof OrganizationMember
     */
    name: string | null;
    /**
     * 表示名。 他のユーザーと被らない方が良い 
     * @type {string}
     * @memberof OrganizationMember
     */
    displayName?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof OrganizationMember
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof OrganizationMember
     */
    updatedAt: string;
    /**
     * 
     * @type {OrganizationMembership}
     * @memberof OrganizationMember
     */
    organizationMembership: OrganizationMembership;
}
/**
 * 
 * @export
 * @interface OrganizationMemberAllOf
 */
export interface OrganizationMemberAllOf {
    /**
     * 
     * @type {OrganizationMembership}
     * @memberof OrganizationMemberAllOf
     */
    organizationMembership?: OrganizationMembership;
}
/**
 * 
 * @export
 * @interface OrganizationMembersResponse
 */
export interface OrganizationMembersResponse {
    /**
     * 
     * @type {Pager}
     * @memberof OrganizationMembersResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<OrganizationMember>}
     * @memberof OrganizationMembersResponse
     */
    data: Array<OrganizationMember>;
}
/**
 * ユーザーの組織への所属情報
 * @export
 * @interface OrganizationMembership
 */
export interface OrganizationMembership {
    /**
     * 組織ID
     * @type {string}
     * @memberof OrganizationMembership
     */
    organizationId: string;
    /**
     * ユーザーID
     * @type {string}
     * @memberof OrganizationMembership
     */
    userId: string;
    /**
     * 
     * @type {UserRole}
     * @memberof OrganizationMembership
     */
    role: UserRole;
}
/**
 * ユーザーが所属する組織
 * @export
 * @interface OrganizationWithMembership
 */
export interface OrganizationWithMembership {
    /**
     * 組織ID
     * @type {string}
     * @memberof OrganizationWithMembership
     */
    id: string;
    /**
     * 名前。 他の組織と被らないものでなければならない 
     * @type {string}
     * @memberof OrganizationWithMembership
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof OrganizationWithMembership
     */
    description?: string | null;
    /**
     * 組織を作成したユーザーのユーザーID
     * @type {string}
     * @memberof OrganizationWithMembership
     */
    createdBy: string;
    /**
     * 組織が無効化されているか
     * @type {boolean}
     * @memberof OrganizationWithMembership
     */
    deactivated?: boolean;
    /**
     * 作成日時
     * @type {string}
     * @memberof OrganizationWithMembership
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof OrganizationWithMembership
     */
    updatedAt: string;
    /**
     * 
     * @type {OrganizationMembership}
     * @memberof OrganizationWithMembership
     */
    membership: OrganizationMembership;
}
/**
 * 
 * @export
 * @interface OrganizationWithMembershipAllOf
 */
export interface OrganizationWithMembershipAllOf {
    /**
     * 
     * @type {OrganizationMembership}
     * @memberof OrganizationWithMembershipAllOf
     */
    membership?: OrganizationMembership;
}
/**
 * 
 * @export
 * @interface OrganizationsResponse
 */
export interface OrganizationsResponse {
    /**
     * 
     * @type {Pager}
     * @memberof OrganizationsResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof OrganizationsResponse
     */
    data: Array<Organization>;
}
/**
 * 
 * @export
 * @interface OrganizationsWithMembershipResponse
 */
export interface OrganizationsWithMembershipResponse {
    /**
     * 
     * @type {Pager}
     * @memberof OrganizationsWithMembershipResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<OrganizationWithMembership>}
     * @memberof OrganizationsWithMembershipResponse
     */
    data: Array<OrganizationWithMembership>;
}
/**
 * レスポンスのページネーション情報
 * @export
 * @interface Pager
 */
export interface Pager {
    /**
     * ページ番号
     * @type {number}
     * @memberof Pager
     */
    page: number;
    /**
     * 1ページあたりの取得件数
     * @type {number}
     * @memberof Pager
     */
    size: number;
    /**
     * 全ページ数
     * @type {number}
     * @memberof Pager
     */
    totalPage: number;
    /**
     * 全ページでのレコードの件数
     * @type {number}
     * @memberof Pager
     */
    totalCount: number;
    /**
     * 前のページがあるかどうか
     * @type {boolean}
     * @memberof Pager
     */
    hasPrevPage: boolean;
    /**
     * 次のページがあるかどうか
     * @type {boolean}
     * @memberof Pager
     */
    hasNextPage: boolean;
}
/**
 * アクセスポリシーに与えられるレイヤーへのアクセス権限
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {PermissionType}
     * @memberof Permission
     */
    type: PermissionType;
}
/**
 * レイヤーへのアクセス権限の種類。  * `read` - イベント、インシデント、フィーチャを取得できる * `write` - イベントを追加できる（インシデント、フィーチャを作成・更新できる） * `manage` - レイヤーを更新できる。他ユーザーのイベントを更新できる 
 * @export
 * @enum {string}
 */

export enum PermissionType {
    Read = 'read',
    Write = 'write',
    Manage = 'manage'
}

/**
 * 地図に表示されるデータのリクエスト
 * @export
 * @interface RequestFeatureCore
 */
export interface RequestFeatureCore {
    /**
     * 
     * @type {FeatureType}
     * @memberof RequestFeatureCore
     */
    type: FeatureType;
    /**
     * 
     * @type {Geometry}
     * @memberof RequestFeatureCore
     */
    geometry?: Geometry | null;
    /**
     * 
     * @type {FeatureProperty}
     * @memberof RequestFeatureCore
     */
    properties?: FeatureProperty | null;
}
/**
 * 
 * @export
 * @interface ResponseComponentMessage
 */
export interface ResponseComponentMessage {
    /**
     * メッセージ
     * @type {string}
     * @memberof ResponseComponentMessage
     */
    message?: string;
}
/**
 * チーム。 複数ユーザーをまとめたもの。 主目的はレイヤーのアクセス権管理 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * チームID
     * @type {string}
     * @memberof Team
     */
    id: string;
    /**
     * 属す組織のID
     * @type {string}
     * @memberof Team
     */
    organizationId: string;
    /**
     * 名前
     * @type {string}
     * @memberof Team
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof Team
     */
    description?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof Team
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof Team
     */
    updatedAt: string;
}
/**
 * レイヤーにアクセスできるチーム
 * @export
 * @interface TeamWithPermission
 */
export interface TeamWithPermission {
    /**
     * チームID
     * @type {string}
     * @memberof TeamWithPermission
     */
    id: string;
    /**
     * 属す組織のID
     * @type {string}
     * @memberof TeamWithPermission
     */
    organizationId: string;
    /**
     * 名前
     * @type {string}
     * @memberof TeamWithPermission
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof TeamWithPermission
     */
    description?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof TeamWithPermission
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof TeamWithPermission
     */
    updatedAt: string;
    /**
     * 
     * @type {Permission}
     * @memberof TeamWithPermission
     */
    permission: Permission;
}
/**
 * 
 * @export
 * @interface TeamsResponse
 */
export interface TeamsResponse {
    /**
     * 
     * @type {Pager}
     * @memberof TeamsResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<Team>}
     * @memberof TeamsResponse
     */
    data: Array<Team>;
}
/**
 * 
 * @export
 * @interface TeamsWithPermissionResponse
 */
export interface TeamsWithPermissionResponse {
    /**
     * 
     * @type {Pager}
     * @memberof TeamsWithPermissionResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<TeamWithPermission>}
     * @memberof TeamsWithPermissionResponse
     */
    data: Array<TeamWithPermission>;
}
/**
 * @type UpdatableEventContent
 * イベントの更新内容。 ユーザーが気になってUIから更新する流れだけを考慮。 コメントの編集とスタンプ、写真、ビデオ、ファイルの削除(ダミーファイルのIDを渡す等)を想定 
 * @export
 */
export type UpdatableEventContent = EventContentForComment | EventContentForFile | EventContentForImage | EventContentForStamp | EventContentForVideo;

/**
 * イベントの更新リクエスト
 * @export
 * @interface UpdateEventRequest
 */
export interface UpdateEventRequest {
    /**
     * 
     * @type {UpdatableEventContent}
     * @memberof UpdateEventRequest
     */
    content: UpdatableEventContent;
}
/**
 * インシデント更新リクエスト
 * @export
 * @interface UpdateIncidentRequest
 */
export interface UpdateIncidentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateIncidentRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIncidentRequest
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIncidentRequest
     */
    isOpen?: boolean | null;
    /**
     * 
     * @type {RequestFeatureCore}
     * @memberof UpdateIncidentRequest
     */
    feature?: RequestFeatureCore | null;
}
/**
 * レイヤーの更新リクエスト
 * @export
 * @interface UpdateLayerRequest
 */
export interface UpdateLayerRequest {
    /**
     * 名前
     * @type {string}
     * @memberof UpdateLayerRequest
     */
    name?: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof UpdateLayerRequest
     */
    description?: string | null;
    /**
     * 公開レイヤーか
     * @type {boolean}
     * @memberof UpdateLayerRequest
     */
    isPublic?: boolean | null;
}
/**
 * 組織の無効化フラグの更新リクエスト
 * @export
 * @interface UpdateOrganizationDeactivationRequest
 */
export interface UpdateOrganizationDeactivationRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDeactivationRequest
     */
    deactivated?: boolean;
}
/**
 * 組織でのユーザーの役割更新リクエスト
 * @export
 * @interface UpdateOrganizationMemberRequest
 */
export interface UpdateOrganizationMemberRequest {
    /**
     * 
     * @type {UserRole}
     * @memberof UpdateOrganizationMemberRequest
     */
    role: UserRole;
}
/**
 * 組織更新リクエスト
 * @export
 * @interface UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    name?: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    description?: string | null;
}
/**
 * アクセス権限更新リクエスト
 * @export
 * @interface UpdatePermissionRequest
 */
export interface UpdatePermissionRequest {
    /**
     * 
     * @type {PermissionType}
     * @memberof UpdatePermissionRequest
     */
    type: PermissionType;
}
/**
 * チーム更新リクエスト
 * @export
 * @interface UpdateTeamRequest
 */
export interface UpdateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamRequest
     */
    name?: string | null;
    /**
     * 説明
     * @type {string}
     * @memberof UpdateTeamRequest
     */
    description?: string | null;
}
/**
 * ユーザー更新リクエスト
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * ログインユーザー名。 他のユーザーと被らないものでなければならない 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    name?: string | null;
    /**
     * 表示名。 他のユーザーと被らない方が良い 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    displayName?: string | null;
}
/**
 * ユーザー
 * @export
 * @interface User
 */
export interface User {
    /**
     * ユーザーID
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * ログインユーザー名。 他のユーザーと被らないものでなければならない 
     * @type {string}
     * @memberof User
     */
    name: string | null;
    /**
     * 表示名。 他のユーザーと被らない方が良い 
     * @type {string}
     * @memberof User
     */
    displayName?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof User
     */
    createdAt: string;
    /**
     * 更新日時（最初は作成日時）
     * @type {string}
     * @memberof User
     */
    updatedAt: string;
}
/**
 * 組織でのユーザーの役割
 * @export
 * @enum {string}
 */

export enum UserRole {
    Admin = 'admin',
    Member = 'member'
}

/**
 * 
 * @export
 * @interface UsersResponse
 */
export interface UsersResponse {
    /**
     * 
     * @type {Pager}
     * @memberof UsersResponse
     */
    pager: Pager;
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersResponse
     */
    data: Array<User>;
}

/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * attachToで指定したイベントがさらに他のイベントにattachToしていた場合、作成されるイベントのattachToは指定したイベントのattachToで上書きされる - リクエストで指定したattachTo: イベントA - イベントAのattachTo: イベントB - 実際に作成されるイベントのattachTo: イベントB 
         * @summary インシデントに紐づくイベントを作成する
         * @param {string} incidentId 対象のインシデントID
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventOfIncident: async (incidentId: string, createEventRequest: CreateEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('createEventOfIncident', 'incidentId', incidentId)
            // verify required parameter 'createEventRequest' is not null or undefined
            assertParamExists('createEventOfIncident', 'createEventRequest', createEventRequest)
            const localVarPath = `/incidents/{incidentId}/events`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [createEventOfIncident](#operation/createEventOfIncident)に移行
         * @summary レイヤー上のインシデントに紐づくイベントを作成する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createEventOfIncidentOfLayer: async (layerId: string, incidentId: string, createEventRequest: CreateEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('createEventOfIncidentOfLayer', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('createEventOfIncidentOfLayer', 'incidentId', incidentId)
            // verify required parameter 'createEventRequest' is not null or undefined
            assertParamExists('createEventOfIncidentOfLayer', 'createEventRequest', createEventRequest)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/events`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 最新でないイベントを削除するとフィーチャの再構成が必要になるためおすすめできない
         * @summary インシデントに紐づくイベントを削除する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteEventOfIncident: async (layerId: string, incidentId: string, eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('deleteEventOfIncident', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('deleteEventOfIncident', 'incidentId', incidentId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteEventOfIncident', 'eventId', eventId)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/events/{eventId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イベントを返す
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEvent', 'eventId', eventId)
            const localVarPath = `/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [getEvent](#operation/getEvent)に移行
         * @summary インシデントに紐づくイベントを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEventOfIncident: async (layerId: string, incidentId: string, eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getEventOfIncident', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getEventOfIncident', 'incidentId', incidentId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventOfIncident', 'eventId', eventId)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/events/{eventId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イベントの一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<EventType>} [eventTypes] イベントタイプの指定
         * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, eventTypes?: Array<EventType>, attachTo?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organizationId) {
                localVarQueryParameter['organizationId'] = organizationId.join(COLLECTION_FORMATS.csv);
            }

            if (layerId) {
                localVarQueryParameter['layerId'] = layerId.join(COLLECTION_FORMATS.csv);
            }

            if (eventTypes) {
                localVarQueryParameter['eventTypes'] = eventTypes.join(COLLECTION_FORMATS.csv);
            }

            if (attachTo) {
                localVarQueryParameter['attachTo'] = attachTo.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary インシデントに紐づくイベントの一覧を返す
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsFromIncident: async (incidentId: string, page?: number, size?: number, sort?: string, attachTo?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getEventsFromIncident', 'incidentId', incidentId)
            const localVarPath = `/incidents/{incidentId}/events`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (attachTo) {
                localVarQueryParameter['attachTo'] = attachTo.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [getEventsFromIncident](#operation/getEventsFromIncident)に移行
         * @summary レイヤー上のインシデントに紐づくイベントの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEventsFromIncidentOfLayer: async (layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getEventsFromIncidentOfLayer', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getEventsFromIncidentOfLayer', 'incidentId', incidentId)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/events`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 内容の変更のみ対応する
         * @summary イベントを更新する
         * @param {string} eventId 対象のイベントID
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (eventId: string, updateEventRequest: UpdateEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateEvent', 'eventId', eventId)
            // verify required parameter 'updateEventRequest' is not null or undefined
            assertParamExists('updateEvent', 'updateEventRequest', updateEventRequest)
            const localVarPath = `/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [updateEvent](#operation/updateEvent)に移行
         * @summary インシデントに紐づくイベントを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateEventOfIncident: async (layerId: string, incidentId: string, eventId: string, updateEventRequest: UpdateEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('updateEventOfIncident', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('updateEventOfIncident', 'incidentId', incidentId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateEventOfIncident', 'eventId', eventId)
            // verify required parameter 'updateEventRequest' is not null or undefined
            assertParamExists('updateEventOfIncident', 'updateEventRequest', updateEventRequest)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/events/{eventId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * attachToで指定したイベントがさらに他のイベントにattachToしていた場合、作成されるイベントのattachToは指定したイベントのattachToで上書きされる - リクエストで指定したattachTo: イベントA - イベントAのattachTo: イベントB - 実際に作成されるイベントのattachTo: イベントB 
         * @summary インシデントに紐づくイベントを作成する
         * @param {string} incidentId 対象のインシデントID
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventOfIncident(incidentId: string, createEventRequest: CreateEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventOfIncident(incidentId, createEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [createEventOfIncident](#operation/createEventOfIncident)に移行
         * @summary レイヤー上のインシデントに紐づくイベントを作成する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createEventOfIncidentOfLayer(layerId: string, incidentId: string, createEventRequest: CreateEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventOfIncidentOfLayer(layerId, incidentId, createEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 最新でないイベントを削除するとフィーチャの再構成が必要になるためおすすめできない
         * @summary インシデントに紐づくイベントを削除する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteEventOfIncident(layerId: string, incidentId: string, eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventOfIncident(layerId, incidentId, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary イベントを返す
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [getEvent](#operation/getEvent)に移行
         * @summary インシデントに紐づくイベントを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getEventOfIncident(layerId: string, incidentId: string, eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventOfIncident(layerId, incidentId, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary イベントの一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<EventType>} [eventTypes] イベントタイプの指定
         * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, eventTypes?: Array<EventType>, attachTo?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(page, size, sort, organizationId, layerId, eventTypes, attachTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary インシデントに紐づくイベントの一覧を返す
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsFromIncident(incidentId: string, page?: number, size?: number, sort?: string, attachTo?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsFromIncident(incidentId, page, size, sort, attachTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [getEventsFromIncident](#operation/getEventsFromIncident)に移行
         * @summary レイヤー上のインシデントに紐づくイベントの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getEventsFromIncidentOfLayer(layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsFromIncidentOfLayer(layerId, incidentId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 内容の変更のみ対応する
         * @summary イベントを更新する
         * @param {string} eventId 対象のイベントID
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(eventId: string, updateEventRequest: UpdateEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(eventId, updateEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [updateEvent](#operation/updateEvent)に移行
         * @summary インシデントに紐づくイベントを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateEventOfIncident(layerId: string, incidentId: string, eventId: string, updateEventRequest: UpdateEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventOfIncident(layerId, incidentId, eventId, updateEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * attachToで指定したイベントがさらに他のイベントにattachToしていた場合、作成されるイベントのattachToは指定したイベントのattachToで上書きされる - リクエストで指定したattachTo: イベントA - イベントAのattachTo: イベントB - 実際に作成されるイベントのattachTo: イベントB 
         * @summary インシデントに紐づくイベントを作成する
         * @param {string} incidentId 対象のインシデントID
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventOfIncident(incidentId: string, createEventRequest: CreateEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.createEventOfIncident(incidentId, createEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * [createEventOfIncident](#operation/createEventOfIncident)に移行
         * @summary レイヤー上のインシデントに紐づくイベントを作成する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createEventOfIncidentOfLayer(layerId: string, incidentId: string, createEventRequest: CreateEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.createEventOfIncidentOfLayer(layerId, incidentId, createEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 最新でないイベントを削除するとフィーチャの再構成が必要になるためおすすめできない
         * @summary インシデントに紐づくイベントを削除する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteEventOfIncident(layerId: string, incidentId: string, eventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEventOfIncident(layerId, incidentId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イベントを返す
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(eventId: string, options?: any): AxiosPromise<Event> {
            return localVarFp.getEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * [getEvent](#operation/getEvent)に移行
         * @summary インシデントに紐づくイベントを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEventOfIncident(layerId: string, incidentId: string, eventId: string, options?: any): AxiosPromise<Event> {
            return localVarFp.getEventOfIncident(layerId, incidentId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イベントの一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<EventType>} [eventTypes] イベントタイプの指定
         * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, eventTypes?: Array<EventType>, attachTo?: Array<string>, options?: any): AxiosPromise<EventsResponse> {
            return localVarFp.getEvents(page, size, sort, organizationId, layerId, eventTypes, attachTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary インシデントに紐づくイベントの一覧を返す
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsFromIncident(incidentId: string, page?: number, size?: number, sort?: string, attachTo?: Array<string>, options?: any): AxiosPromise<EventsResponse> {
            return localVarFp.getEventsFromIncident(incidentId, page, size, sort, attachTo, options).then((request) => request(axios, basePath));
        },
        /**
         * [getEventsFromIncident](#operation/getEventsFromIncident)に移行
         * @summary レイヤー上のインシデントに紐づくイベントの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEventsFromIncidentOfLayer(layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<EventsResponse> {
            return localVarFp.getEventsFromIncidentOfLayer(layerId, incidentId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 内容の変更のみ対応する
         * @summary イベントを更新する
         * @param {string} eventId 対象のイベントID
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(eventId: string, updateEventRequest: UpdateEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.updateEvent(eventId, updateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * [updateEvent](#operation/updateEvent)に移行
         * @summary インシデントに紐づくイベントを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} eventId 対象のイベントID
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateEventOfIncident(layerId: string, incidentId: string, eventId: string, updateEventRequest: UpdateEventRequest, options?: any): AxiosPromise<Event> {
            return localVarFp.updateEventOfIncident(layerId, incidentId, eventId, updateEventRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * attachToで指定したイベントがさらに他のイベントにattachToしていた場合、作成されるイベントのattachToは指定したイベントのattachToで上書きされる - リクエストで指定したattachTo: イベントA - イベントAのattachTo: イベントB - 実際に作成されるイベントのattachTo: イベントB 
     * @summary インシデントに紐づくイベントを作成する
     * @param {string} incidentId 対象のインシデントID
     * @param {CreateEventRequest} createEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public createEventOfIncident(incidentId: string, createEventRequest: CreateEventRequest, options?: any) {
        return EventApiFp(this.configuration).createEventOfIncident(incidentId, createEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [createEventOfIncident](#operation/createEventOfIncident)に移行
     * @summary レイヤー上のインシデントに紐づくイベントを作成する
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {CreateEventRequest} createEventRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public createEventOfIncidentOfLayer(layerId: string, incidentId: string, createEventRequest: CreateEventRequest, options?: any) {
        return EventApiFp(this.configuration).createEventOfIncidentOfLayer(layerId, incidentId, createEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 最新でないイベントを削除するとフィーチャの再構成が必要になるためおすすめできない
     * @summary インシデントに紐づくイベントを削除する
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {string} eventId 対象のイベントID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public deleteEventOfIncident(layerId: string, incidentId: string, eventId: string, options?: any) {
        return EventApiFp(this.configuration).deleteEventOfIncident(layerId, incidentId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イベントを返す
     * @param {string} eventId 対象のイベントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvent(eventId: string, options?: any) {
        return EventApiFp(this.configuration).getEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [getEvent](#operation/getEvent)に移行
     * @summary インシデントに紐づくイベントを返す
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {string} eventId 対象のイベントID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEventOfIncident(layerId: string, incidentId: string, eventId: string, options?: any) {
        return EventApiFp(this.configuration).getEventOfIncident(layerId, incidentId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イベントの一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
     * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
     * @param {Array<EventType>} [eventTypes] イベントタイプの指定
     * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvents(page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, eventTypes?: Array<EventType>, attachTo?: Array<string>, options?: any) {
        return EventApiFp(this.configuration).getEvents(page, size, sort, organizationId, layerId, eventTypes, attachTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary インシデントに紐づくイベントの一覧を返す
     * @param {string} incidentId 対象のインシデントID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {Array<string>} [attachTo] 添付先イベントIDの指定 （nullを指定するとattachToがnull値のイベントが返る） 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEventsFromIncident(incidentId: string, page?: number, size?: number, sort?: string, attachTo?: Array<string>, options?: any) {
        return EventApiFp(this.configuration).getEventsFromIncident(incidentId, page, size, sort, attachTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [getEventsFromIncident](#operation/getEventsFromIncident)に移行
     * @summary レイヤー上のインシデントに紐づくイベントの一覧を返す
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEventsFromIncidentOfLayer(layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options?: any) {
        return EventApiFp(this.configuration).getEventsFromIncidentOfLayer(layerId, incidentId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 内容の変更のみ対応する
     * @summary イベントを更新する
     * @param {string} eventId 対象のイベントID
     * @param {UpdateEventRequest} updateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public updateEvent(eventId: string, updateEventRequest: UpdateEventRequest, options?: any) {
        return EventApiFp(this.configuration).updateEvent(eventId, updateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [updateEvent](#operation/updateEvent)に移行
     * @summary インシデントに紐づくイベントを更新する
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {string} eventId 対象のイベントID
     * @param {UpdateEventRequest} updateEventRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public updateEventOfIncident(layerId: string, incidentId: string, eventId: string, updateEventRequest: UpdateEventRequest, options?: any) {
        return EventApiFp(this.configuration).updateEventOfIncident(layerId, incidentId, eventId, updateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary フィーチャを返す
         * @param {string} featureId 対象のフィーチャID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeature: async (featureId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('getFeature', 'featureId', featureId)
            const localVarPath = `/features/{featureId}`
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [getFeature](#operation/getFeature)に移行
         * @summary インシデントに紐づくフィーチャを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} featureId 対象のフィーチャID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFeatureOfIncident: async (layerId: string, incidentId: string, featureId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getFeatureOfIncident', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getFeatureOfIncident', 'incidentId', incidentId)
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('getFeatureOfIncident', 'featureId', featureId)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/features/{featureId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フィーチャの一覧を返す
         * @param {BoundingBox} [bbox] 地図範囲の指定。 [参考](https://wiki.openstreetmap.org/wiki/Bounding_Box) 
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (bbox?: BoundingBox, page?: number, size?: number, sort?: string, layerId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (layerId) {
                localVarQueryParameter['layerId'] = layerId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary インシデントに紐づくフィーチャの一覧を返す
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesFromIncident: async (incidentId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getFeaturesFromIncident', 'incidentId', incidentId)
            const localVarPath = `/incidents/{incidentId}/features`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [getFeaturesFromIncident](#operation/getFeaturesFromIncident)に移行
         * @summary レイヤー上のインシデントに紐づくフィーチャの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFeaturesFromIncidentOfLayer: async (layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getFeaturesFromIncidentOfLayer', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getFeaturesFromIncidentOfLayer', 'incidentId', incidentId)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}/features`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary フィーチャを返す
         * @param {string} featureId 対象のフィーチャID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeature(featureId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeature(featureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [getFeature](#operation/getFeature)に移行
         * @summary インシデントに紐づくフィーチャを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} featureId 対象のフィーチャID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFeatureOfIncident(layerId: string, incidentId: string, featureId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureOfIncident(layerId, incidentId, featureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フィーチャの一覧を返す
         * @param {BoundingBox} [bbox] 地図範囲の指定。 [参考](https://wiki.openstreetmap.org/wiki/Bounding_Box) 
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(bbox?: BoundingBox, page?: number, size?: number, sort?: string, layerId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(bbox, page, size, sort, layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary インシデントに紐づくフィーチャの一覧を返す
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturesFromIncident(incidentId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesFromIncident(incidentId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [getFeaturesFromIncident](#operation/getFeaturesFromIncident)に移行
         * @summary レイヤー上のインシデントに紐づくフィーチャの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFeaturesFromIncidentOfLayer(layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesFromIncidentOfLayer(layerId, incidentId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * 
         * @summary フィーチャを返す
         * @param {string} featureId 対象のフィーチャID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeature(featureId: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.getFeature(featureId, options).then((request) => request(axios, basePath));
        },
        /**
         * [getFeature](#operation/getFeature)に移行
         * @summary インシデントに紐づくフィーチャを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {string} featureId 対象のフィーチャID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFeatureOfIncident(layerId: string, incidentId: string, featureId: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.getFeatureOfIncident(layerId, incidentId, featureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フィーチャの一覧を返す
         * @param {BoundingBox} [bbox] 地図範囲の指定。 [参考](https://wiki.openstreetmap.org/wiki/Bounding_Box) 
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(bbox?: BoundingBox, page?: number, size?: number, sort?: string, layerId?: Array<string>, options?: any): AxiosPromise<FeaturesResponse> {
            return localVarFp.getFeatures(bbox, page, size, sort, layerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary インシデントに紐づくフィーチャの一覧を返す
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesFromIncident(incidentId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<FeaturesResponse> {
            return localVarFp.getFeaturesFromIncident(incidentId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * [getFeaturesFromIncident](#operation/getFeaturesFromIncident)に移行
         * @summary レイヤー上のインシデントに紐づくフィーチャの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFeaturesFromIncidentOfLayer(layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<FeaturesResponse> {
            return localVarFp.getFeaturesFromIncidentOfLayer(layerId, incidentId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI {
    /**
     * 
     * @summary フィーチャを返す
     * @param {string} featureId 対象のフィーチャID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeature(featureId: string, options?: any) {
        return FeatureApiFp(this.configuration).getFeature(featureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [getFeature](#operation/getFeature)に移行
     * @summary インシデントに紐づくフィーチャを返す
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {string} featureId 対象のフィーチャID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureOfIncident(layerId: string, incidentId: string, featureId: string, options?: any) {
        return FeatureApiFp(this.configuration).getFeatureOfIncident(layerId, incidentId, featureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フィーチャの一覧を返す
     * @param {BoundingBox} [bbox] 地図範囲の指定。 [参考](https://wiki.openstreetmap.org/wiki/Bounding_Box) 
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatures(bbox?: BoundingBox, page?: number, size?: number, sort?: string, layerId?: Array<string>, options?: any) {
        return FeatureApiFp(this.configuration).getFeatures(bbox, page, size, sort, layerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary インシデントに紐づくフィーチャの一覧を返す
     * @param {string} incidentId 対象のインシデントID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeaturesFromIncident(incidentId: string, page?: number, size?: number, sort?: string, options?: any) {
        return FeatureApiFp(this.configuration).getFeaturesFromIncident(incidentId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [getFeaturesFromIncident](#operation/getFeaturesFromIncident)に移行
     * @summary レイヤー上のインシデントに紐づくフィーチャの一覧を返す
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeaturesFromIncidentOfLayer(layerId: string, incidentId: string, page?: number, size?: number, sort?: string, options?: any) {
        return FeatureApiFp(this.configuration).getFeaturesFromIncidentOfLayer(layerId, incidentId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 正常に作成された場合、対応するファイルアップロード用チケットは削除される
         * @summary ファイルを作成する
         * @param {CreateFileRequest} createFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (createFileRequest: CreateFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFileRequest' is not null or undefined
            assertParamExists('createFile', 'createFileRequest', createFileRequest)
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [createFileTicketOnLayer](#operation/createFileTicketOnLayer)に移行
         * @summary ファイルアップロード用チケットを発行する
         * @param {CreateFileTicketRequestWithOrgId} createFileTicketRequestWithOrgId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createFileTicket: async (createFileTicketRequestWithOrgId: CreateFileTicketRequestWithOrgId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFileTicketRequestWithOrgId' is not null or undefined
            assertParamExists('createFileTicket', 'createFileTicketRequestWithOrgId', createFileTicketRequestWithOrgId)
            const localVarPath = `/files/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileTicketRequestWithOrgId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * このチケットの情報を使ってファイルをアップロードする。 アップロード後にファイル作成APIに渡してファイルを作成する。 ファイルを作成するとチケットは削除される。 チケットには有効期限があり、有効期限が切れた場合もチケットは削除される。 
         * @summary ファイルアップロード用チケットを発行する
         * @param {string} layerId 対象のレイヤーID
         * @param {CreateFileTicketRequest} createFileTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileTicketOnLayer: async (layerId: string, createFileTicketRequest: CreateFileTicketRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('createFileTicketOnLayer', 'layerId', layerId)
            // verify required parameter 'createFileTicketRequest' is not null or undefined
            assertParamExists('createFileTicketOnLayer', 'createFileTicketRequest', createFileTicketRequest)
            const localVarPath = `/layers/{layerId}/files/tickets`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ファイルを削除する
         * @param {string} fileId 対象のファイルID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteFile: async (fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFile', 'fileId', fileId)
            const localVarPath = `/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ファイルを返す
         * @param {string} fileId 対象のファイルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFile', 'fileId', fileId)
            const localVarPath = `/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ファイル一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 正常に作成された場合、対応するファイルアップロード用チケットは削除される
         * @summary ファイルを作成する
         * @param {CreateFileRequest} createFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(createFileRequest: CreateFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(createFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [createFileTicketOnLayer](#operation/createFileTicketOnLayer)に移行
         * @summary ファイルアップロード用チケットを発行する
         * @param {CreateFileTicketRequestWithOrgId} createFileTicketRequestWithOrgId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createFileTicket(createFileTicketRequestWithOrgId: CreateFileTicketRequestWithOrgId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileTicket(createFileTicketRequestWithOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * このチケットの情報を使ってファイルをアップロードする。 アップロード後にファイル作成APIに渡してファイルを作成する。 ファイルを作成するとチケットは削除される。 チケットには有効期限があり、有効期限が切れた場合もチケットは削除される。 
         * @summary ファイルアップロード用チケットを発行する
         * @param {string} layerId 対象のレイヤーID
         * @param {CreateFileTicketRequest} createFileTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileTicketOnLayer(layerId: string, createFileTicketRequest: CreateFileTicketRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileTicketOnLayer(layerId, createFileTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ファイルを削除する
         * @param {string} fileId 対象のファイルID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteFile(fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ファイルを返す
         * @param {string} fileId 対象のファイルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ファイル一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiles(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 正常に作成された場合、対応するファイルアップロード用チケットは削除される
         * @summary ファイルを作成する
         * @param {CreateFileRequest} createFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(createFileRequest: CreateFileRequest, options?: any): AxiosPromise<any> {
            return localVarFp.createFile(createFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * [createFileTicketOnLayer](#operation/createFileTicketOnLayer)に移行
         * @summary ファイルアップロード用チケットを発行する
         * @param {CreateFileTicketRequestWithOrgId} createFileTicketRequestWithOrgId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createFileTicket(createFileTicketRequestWithOrgId: CreateFileTicketRequestWithOrgId, options?: any): AxiosPromise<FileTicket> {
            return localVarFp.createFileTicket(createFileTicketRequestWithOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * このチケットの情報を使ってファイルをアップロードする。 アップロード後にファイル作成APIに渡してファイルを作成する。 ファイルを作成するとチケットは削除される。 チケットには有効期限があり、有効期限が切れた場合もチケットは削除される。 
         * @summary ファイルアップロード用チケットを発行する
         * @param {string} layerId 対象のレイヤーID
         * @param {CreateFileTicketRequest} createFileTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileTicketOnLayer(layerId: string, createFileTicketRequest: CreateFileTicketRequest, options?: any): AxiosPromise<FileTicket> {
            return localVarFp.createFileTicketOnLayer(layerId, createFileTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ファイルを削除する
         * @param {string} fileId 対象のファイルID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteFile(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ファイルを返す
         * @param {string} fileId 対象のファイルID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(fileId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ファイル一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<FilesResponse> {
            return localVarFp.getFiles(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 正常に作成された場合、対応するファイルアップロード用チケットは削除される
     * @summary ファイルを作成する
     * @param {CreateFileRequest} createFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFile(createFileRequest: CreateFileRequest, options?: any) {
        return FileApiFp(this.configuration).createFile(createFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [createFileTicketOnLayer](#operation/createFileTicketOnLayer)に移行
     * @summary ファイルアップロード用チケットを発行する
     * @param {CreateFileTicketRequestWithOrgId} createFileTicketRequestWithOrgId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFileTicket(createFileTicketRequestWithOrgId: CreateFileTicketRequestWithOrgId, options?: any) {
        return FileApiFp(this.configuration).createFileTicket(createFileTicketRequestWithOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * このチケットの情報を使ってファイルをアップロードする。 アップロード後にファイル作成APIに渡してファイルを作成する。 ファイルを作成するとチケットは削除される。 チケットには有効期限があり、有効期限が切れた場合もチケットは削除される。 
     * @summary ファイルアップロード用チケットを発行する
     * @param {string} layerId 対象のレイヤーID
     * @param {CreateFileTicketRequest} createFileTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFileTicketOnLayer(layerId: string, createFileTicketRequest: CreateFileTicketRequest, options?: any) {
        return FileApiFp(this.configuration).createFileTicketOnLayer(layerId, createFileTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ファイルを削除する
     * @param {string} fileId 対象のファイルID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFile(fileId: string, options?: any) {
        return FileApiFp(this.configuration).deleteFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ファイルを返す
     * @param {string} fileId 対象のファイルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFile(fileId: string, options?: any) {
        return FileApiFp(this.configuration).getFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ファイル一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFiles(page?: number, size?: number, sort?: string, options?: any) {
        return FileApiFp(this.configuration).getFiles(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IncidentApi - axios parameter creator
 * @export
 */
export const IncidentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したパラメータの設定イベントが発生する。 必要ならフィーチャも作成される。 パラメータの少なくとも1つは必須。 そのため必ず1つ以上のイベントと一緒に作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | 作成されない | | description | `set_title` | 作成されない | | feature | `set_feature` | 作成される | 
         * @summary レイヤー上のインシデントを作成する
         * @param {string} layerId 対象のレイヤーID
         * @param {CreateIncidentRequest} createIncidentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIncidentOfLayer: async (layerId: string, createIncidentRequest: CreateIncidentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('createIncidentOfLayer', 'layerId', layerId)
            // verify required parameter 'createIncidentRequest' is not null or undefined
            assertParamExists('createIncidentOfLayer', 'createIncidentRequest', createIncidentRequest)
            const localVarPath = `/layers/{layerId}/incidents`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIncidentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary インシデントを返す
         * @param {string} incidentId 対象のインシデントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncident: async (incidentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getIncident', 'incidentId', incidentId)
            const localVarPath = `/incidents/{incidentId}`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [getIncident](#operation/getIncident)に移行
         * @summary レイヤー上のインシデントを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIncidentOfLayer: async (layerId: string, incidentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getIncidentOfLayer', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('getIncidentOfLayer', 'incidentId', incidentId)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary インシデントの一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
         * @param {string} [name] インシデント名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidents: async (page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, includeClosed?: boolean, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/incidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organizationId) {
                localVarQueryParameter['organizationId'] = organizationId.join(COLLECTION_FORMATS.csv);
            }

            if (layerId) {
                localVarQueryParameter['layerId'] = layerId.join(COLLECTION_FORMATS.csv);
            }

            if (includeClosed !== undefined) {
                localVarQueryParameter['includeClosed'] = includeClosed;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レイヤー上のインシデントの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
         * @param {string} [name] インシデント名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidentsFromLayer: async (layerId: string, page?: number, size?: number, sort?: string, includeClosed?: boolean, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getIncidentsFromLayer', 'layerId', layerId)
            const localVarPath = `/layers/{layerId}/incidents`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (includeClosed !== undefined) {
                localVarQueryParameter['includeClosed'] = includeClosed;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実際に変更がある場合はインシデントの設定イベントが発生する。 必要ならフィーチャも作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | \\*1 | | description | `set_title` | \\*1 | | isOpen | `close` or `reopen` | \\*2 | | feature | `set_feature` | 作成される |  \\*1 既にフィーチャがある場合はタイトルや説明を更新したフィーチャが作成される。 そうでなければ作成されない。 \\*2 isOpenをfalseにした場合、その時点で最新のフィーチャのlatestUntilをその日時に設定する。 trueにした場合、インシデントのfeatureが設定させていればフィーチャが作成される。 
         * @summary インシデントを更新する
         * @param {string} incidentId 対象のインシデントID
         * @param {UpdateIncidentRequest} updateIncidentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncident: async (incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('updateIncident', 'incidentId', incidentId)
            // verify required parameter 'updateIncidentRequest' is not null or undefined
            assertParamExists('updateIncident', 'updateIncidentRequest', updateIncidentRequest)
            const localVarPath = `/incidents/{incidentId}`
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIncidentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [updateIncident](#operation/updateIncident)に移行
         * @summary レイヤー上のインシデントを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {UpdateIncidentRequest} updateIncidentRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateIncidentOfLayer: async (layerId: string, incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('updateIncidentOfLayer', 'layerId', layerId)
            // verify required parameter 'incidentId' is not null or undefined
            assertParamExists('updateIncidentOfLayer', 'incidentId', incidentId)
            // verify required parameter 'updateIncidentRequest' is not null or undefined
            assertParamExists('updateIncidentOfLayer', 'updateIncidentRequest', updateIncidentRequest)
            const localVarPath = `/layers/{layerId}/incidents/{incidentId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)))
                .replace(`{${"incidentId"}}`, encodeURIComponent(String(incidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIncidentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncidentApi - functional programming interface
 * @export
 */
export const IncidentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IncidentApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したパラメータの設定イベントが発生する。 必要ならフィーチャも作成される。 パラメータの少なくとも1つは必須。 そのため必ず1つ以上のイベントと一緒に作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | 作成されない | | description | `set_title` | 作成されない | | feature | `set_feature` | 作成される | 
         * @summary レイヤー上のインシデントを作成する
         * @param {string} layerId 対象のレイヤーID
         * @param {CreateIncidentRequest} createIncidentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIncidentOfLayer(layerId: string, createIncidentRequest: CreateIncidentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Incident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIncidentOfLayer(layerId, createIncidentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary インシデントを返す
         * @param {string} incidentId 対象のインシデントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncident(incidentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Incident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncident(incidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [getIncident](#operation/getIncident)に移行
         * @summary レイヤー上のインシデントを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getIncidentOfLayer(layerId: string, incidentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Incident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncidentOfLayer(layerId, incidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary インシデントの一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
         * @param {string} [name] インシデント名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncidents(page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, includeClosed?: boolean, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncidentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncidents(page, size, sort, organizationId, layerId, includeClosed, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レイヤー上のインシデントの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
         * @param {string} [name] インシデント名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncidentsFromLayer(layerId: string, page?: number, size?: number, sort?: string, includeClosed?: boolean, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncidentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncidentsFromLayer(layerId, page, size, sort, includeClosed, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実際に変更がある場合はインシデントの設定イベントが発生する。 必要ならフィーチャも作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | \\*1 | | description | `set_title` | \\*1 | | isOpen | `close` or `reopen` | \\*2 | | feature | `set_feature` | 作成される |  \\*1 既にフィーチャがある場合はタイトルや説明を更新したフィーチャが作成される。 そうでなければ作成されない。 \\*2 isOpenをfalseにした場合、その時点で最新のフィーチャのlatestUntilをその日時に設定する。 trueにした場合、インシデントのfeatureが設定させていればフィーチャが作成される。 
         * @summary インシデントを更新する
         * @param {string} incidentId 対象のインシデントID
         * @param {UpdateIncidentRequest} updateIncidentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIncident(incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Incident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIncident(incidentId, updateIncidentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [updateIncident](#operation/updateIncident)に移行
         * @summary レイヤー上のインシデントを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {UpdateIncidentRequest} updateIncidentRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateIncidentOfLayer(layerId: string, incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Incident>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIncidentOfLayer(layerId, incidentId, updateIncidentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IncidentApi - factory interface
 * @export
 */
export const IncidentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IncidentApiFp(configuration)
    return {
        /**
         * 指定したパラメータの設定イベントが発生する。 必要ならフィーチャも作成される。 パラメータの少なくとも1つは必須。 そのため必ず1つ以上のイベントと一緒に作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | 作成されない | | description | `set_title` | 作成されない | | feature | `set_feature` | 作成される | 
         * @summary レイヤー上のインシデントを作成する
         * @param {string} layerId 対象のレイヤーID
         * @param {CreateIncidentRequest} createIncidentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIncidentOfLayer(layerId: string, createIncidentRequest: CreateIncidentRequest, options?: any): AxiosPromise<Incident> {
            return localVarFp.createIncidentOfLayer(layerId, createIncidentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary インシデントを返す
         * @param {string} incidentId 対象のインシデントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncident(incidentId: string, options?: any): AxiosPromise<Incident> {
            return localVarFp.getIncident(incidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * [getIncident](#operation/getIncident)に移行
         * @summary レイヤー上のインシデントを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIncidentOfLayer(layerId: string, incidentId: string, options?: any): AxiosPromise<Incident> {
            return localVarFp.getIncidentOfLayer(layerId, incidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary インシデントの一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
         * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
         * @param {string} [name] インシデント名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidents(page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, includeClosed?: boolean, name?: string, options?: any): AxiosPromise<IncidentsResponse> {
            return localVarFp.getIncidents(page, size, sort, organizationId, layerId, includeClosed, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レイヤー上のインシデントの一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
         * @param {string} [name] インシデント名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidentsFromLayer(layerId: string, page?: number, size?: number, sort?: string, includeClosed?: boolean, name?: string, options?: any): AxiosPromise<IncidentsResponse> {
            return localVarFp.getIncidentsFromLayer(layerId, page, size, sort, includeClosed, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 実際に変更がある場合はインシデントの設定イベントが発生する。 必要ならフィーチャも作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | \\*1 | | description | `set_title` | \\*1 | | isOpen | `close` or `reopen` | \\*2 | | feature | `set_feature` | 作成される |  \\*1 既にフィーチャがある場合はタイトルや説明を更新したフィーチャが作成される。 そうでなければ作成されない。 \\*2 isOpenをfalseにした場合、その時点で最新のフィーチャのlatestUntilをその日時に設定する。 trueにした場合、インシデントのfeatureが設定させていればフィーチャが作成される。 
         * @summary インシデントを更新する
         * @param {string} incidentId 対象のインシデントID
         * @param {UpdateIncidentRequest} updateIncidentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncident(incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options?: any): AxiosPromise<Incident> {
            return localVarFp.updateIncident(incidentId, updateIncidentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * [updateIncident](#operation/updateIncident)に移行
         * @summary レイヤー上のインシデントを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {string} incidentId 対象のインシデントID
         * @param {UpdateIncidentRequest} updateIncidentRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateIncidentOfLayer(layerId: string, incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options?: any): AxiosPromise<Incident> {
            return localVarFp.updateIncidentOfLayer(layerId, incidentId, updateIncidentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IncidentApi - object-oriented interface
 * @export
 * @class IncidentApi
 * @extends {BaseAPI}
 */
export class IncidentApi extends BaseAPI {
    /**
     * 指定したパラメータの設定イベントが発生する。 必要ならフィーチャも作成される。 パラメータの少なくとも1つは必須。 そのため必ず1つ以上のイベントと一緒に作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | 作成されない | | description | `set_title` | 作成されない | | feature | `set_feature` | 作成される | 
     * @summary レイヤー上のインシデントを作成する
     * @param {string} layerId 対象のレイヤーID
     * @param {CreateIncidentRequest} createIncidentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public createIncidentOfLayer(layerId: string, createIncidentRequest: CreateIncidentRequest, options?: any) {
        return IncidentApiFp(this.configuration).createIncidentOfLayer(layerId, createIncidentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary インシデントを返す
     * @param {string} incidentId 対象のインシデントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public getIncident(incidentId: string, options?: any) {
        return IncidentApiFp(this.configuration).getIncident(incidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [getIncident](#operation/getIncident)に移行
     * @summary レイヤー上のインシデントを返す
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public getIncidentOfLayer(layerId: string, incidentId: string, options?: any) {
        return IncidentApiFp(this.configuration).getIncidentOfLayer(layerId, incidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary インシデントの一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
     * @param {Array<string>} [layerId] レイヤーIDの指定 （複数指定した場合はOR条件になる。） 
     * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
     * @param {string} [name] インシデント名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public getIncidents(page?: number, size?: number, sort?: string, organizationId?: Array<string>, layerId?: Array<string>, includeClosed?: boolean, name?: string, options?: any) {
        return IncidentApiFp(this.configuration).getIncidents(page, size, sort, organizationId, layerId, includeClosed, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レイヤー上のインシデントの一覧を返す
     * @param {string} layerId 対象のレイヤーID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {boolean} [includeClosed] クローズしたインシデントを含むかどうか
     * @param {string} [name] インシデント名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public getIncidentsFromLayer(layerId: string, page?: number, size?: number, sort?: string, includeClosed?: boolean, name?: string, options?: any) {
        return IncidentApiFp(this.configuration).getIncidentsFromLayer(layerId, page, size, sort, includeClosed, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実際に変更がある場合はインシデントの設定イベントが発生する。 必要ならフィーチャも作成される。  | パラメータ | イベント | フィーチャ | |---|---|---| | name | `set_title` | \\*1 | | description | `set_title` | \\*1 | | isOpen | `close` or `reopen` | \\*2 | | feature | `set_feature` | 作成される |  \\*1 既にフィーチャがある場合はタイトルや説明を更新したフィーチャが作成される。 そうでなければ作成されない。 \\*2 isOpenをfalseにした場合、その時点で最新のフィーチャのlatestUntilをその日時に設定する。 trueにした場合、インシデントのfeatureが設定させていればフィーチャが作成される。 
     * @summary インシデントを更新する
     * @param {string} incidentId 対象のインシデントID
     * @param {UpdateIncidentRequest} updateIncidentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public updateIncident(incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options?: any) {
        return IncidentApiFp(this.configuration).updateIncident(incidentId, updateIncidentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [updateIncident](#operation/updateIncident)に移行
     * @summary レイヤー上のインシデントを更新する
     * @param {string} layerId 対象のレイヤーID
     * @param {string} incidentId 対象のインシデントID
     * @param {UpdateIncidentRequest} updateIncidentRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public updateIncidentOfLayer(layerId: string, incidentId: string, updateIncidentRequest: UpdateIncidentRequest, options?: any) {
        return IncidentApiFp(this.configuration).updateIncidentOfLayer(layerId, incidentId, updateIncidentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LayerApi - axios parameter creator
 * @export
 */
export const LayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary レイヤーを作成する
         * @param {CreateLayerRequest} createLayerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLayer: async (createLayerRequest: CreateLayerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLayerRequest' is not null or undefined
            assertParamExists('createLayer', 'createLayerRequest', createLayerRequest)
            const localVarPath = `/layers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLayerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レイヤーを削除する
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayer: async (layerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('deleteLayer', 'layerId', layerId)
            const localVarPath = `/layers/{layerId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レイヤーを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayer: async (layerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getLayer', 'layerId', layerId)
            const localVarPath = `/layers/{layerId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レイヤー一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {string} [name] レイヤー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayers: async (page?: number, size?: number, sort?: string, organizationId?: Array<string>, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/layers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organizationId) {
                localVarQueryParameter['organizationId'] = organizationId.join(COLLECTION_FORMATS.csv);
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のレイヤー一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] レイヤー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayersFromOrganization: async (organizationId: string, page?: number, size?: number, sort?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getLayersFromOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/layers`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レイヤーを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {UpdateLayerRequest} updateLayerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLayer: async (layerId: string, updateLayerRequest: UpdateLayerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('updateLayer', 'layerId', layerId)
            // verify required parameter 'updateLayerRequest' is not null or undefined
            assertParamExists('updateLayer', 'updateLayerRequest', updateLayerRequest)
            const localVarPath = `/layers/{layerId}`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLayerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayerApi - functional programming interface
 * @export
 */
export const LayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary レイヤーを作成する
         * @param {CreateLayerRequest} createLayerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLayer(createLayerRequest: CreateLayerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLayer(createLayerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レイヤーを削除する
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLayer(layerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLayer(layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レイヤーを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayer(layerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayer(layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レイヤー一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {string} [name] レイヤー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayers(page?: number, size?: number, sort?: string, organizationId?: Array<string>, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayers(page, size, sort, organizationId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のレイヤー一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] レイヤー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayersFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayersFromOrganization(organizationId, page, size, sort, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レイヤーを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {UpdateLayerRequest} updateLayerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLayer(layerId: string, updateLayerRequest: UpdateLayerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Layer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLayer(layerId, updateLayerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayerApi - factory interface
 * @export
 */
export const LayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayerApiFp(configuration)
    return {
        /**
         * 
         * @summary レイヤーを作成する
         * @param {CreateLayerRequest} createLayerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLayer(createLayerRequest: CreateLayerRequest, options?: any): AxiosPromise<Layer> {
            return localVarFp.createLayer(createLayerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レイヤーを削除する
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayer(layerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLayer(layerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レイヤーを返す
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayer(layerId: string, options?: any): AxiosPromise<Layer> {
            return localVarFp.getLayer(layerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レイヤー一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
         * @param {string} [name] レイヤー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayers(page?: number, size?: number, sort?: string, organizationId?: Array<string>, name?: string, options?: any): AxiosPromise<LayersResponse> {
            return localVarFp.getLayers(page, size, sort, organizationId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のレイヤー一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] レイヤー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayersFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any): AxiosPromise<LayersResponse> {
            return localVarFp.getLayersFromOrganization(organizationId, page, size, sort, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レイヤーを更新する
         * @param {string} layerId 対象のレイヤーID
         * @param {UpdateLayerRequest} updateLayerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLayer(layerId: string, updateLayerRequest: UpdateLayerRequest, options?: any): AxiosPromise<Layer> {
            return localVarFp.updateLayer(layerId, updateLayerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayerApi - object-oriented interface
 * @export
 * @class LayerApi
 * @extends {BaseAPI}
 */
export class LayerApi extends BaseAPI {
    /**
     * 
     * @summary レイヤーを作成する
     * @param {CreateLayerRequest} createLayerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public createLayer(createLayerRequest: CreateLayerRequest, options?: any) {
        return LayerApiFp(this.configuration).createLayer(createLayerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レイヤーを削除する
     * @param {string} layerId 対象のレイヤーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public deleteLayer(layerId: string, options?: any) {
        return LayerApiFp(this.configuration).deleteLayer(layerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レイヤーを返す
     * @param {string} layerId 対象のレイヤーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public getLayer(layerId: string, options?: any) {
        return LayerApiFp(this.configuration).getLayer(layerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レイヤー一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {Array<string>} [organizationId] 組織IDの指定 （複数指定した場合はOR条件になる。） 
     * @param {string} [name] レイヤー名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public getLayers(page?: number, size?: number, sort?: string, organizationId?: Array<string>, name?: string, options?: any) {
        return LayerApiFp(this.configuration).getLayers(page, size, sort, organizationId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のレイヤー一覧を返す
     * @param {string} organizationId 対象の組織ID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {string} [name] レイヤー名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public getLayersFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any) {
        return LayerApiFp(this.configuration).getLayersFromOrganization(organizationId, page, size, sort, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レイヤーを更新する
     * @param {string} layerId 対象のレイヤーID
     * @param {UpdateLayerRequest} updateLayerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public updateLayer(layerId: string, updateLayerRequest: UpdateLayerRequest, options?: any) {
        return LayerApiFp(this.configuration).updateLayer(layerId, updateLayerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LayerPermissionApi - axios parameter creator
 * @export
 */
export const LayerPermissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary チームにレイヤーのアクセス権限を追加する
         * @param {string} teamId 対象のチームID
         * @param {AddPermissionRequest} addPermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLayerToTeam: async (teamId: string, addPermissionRequest: AddPermissionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addLayerToTeam', 'teamId', teamId)
            // verify required parameter 'addPermissionRequest' is not null or undefined
            assertParamExists('addLayerToTeam', 'addPermissionRequest', addPermissionRequest)
            const localVarPath = `/teams/{teamId}/layers`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームからレイヤーのアクセス権限を削除する
         * @param {string} teamId 対象のチームID
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermissionFromTeam: async (teamId: string, layerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deletePermissionFromTeam', 'teamId', teamId)
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('deletePermissionFromTeam', 'layerId', layerId)
            const localVarPath = `/teams/{teamId}/layers/{layerId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームがアクセス権限を持つレイヤー一覧を返す
         * @param {string} teamId 対象のチームID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayersFromTeam: async (teamId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getLayersFromTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}/layers`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レイヤーへのアクセス権限を持つチーム一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsFromLayer: async (layerId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('getTeamsFromLayer', 'layerId', layerId)
            const localVarPath = `/layers/{layerId}/teams`
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームのレイヤーへのアクセス権限を更新する
         * @param {string} teamId 対象のチームID
         * @param {string} layerId 対象のレイヤーID
         * @param {UpdatePermissionRequest} updatePermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLayerOfTeam: async (teamId: string, layerId: string, updatePermissionRequest: UpdatePermissionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateLayerOfTeam', 'teamId', teamId)
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('updateLayerOfTeam', 'layerId', layerId)
            // verify required parameter 'updatePermissionRequest' is not null or undefined
            assertParamExists('updateLayerOfTeam', 'updatePermissionRequest', updatePermissionRequest)
            const localVarPath = `/teams/{teamId}/layers/{layerId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"layerId"}}`, encodeURIComponent(String(layerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayerPermissionApi - functional programming interface
 * @export
 */
export const LayerPermissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayerPermissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary チームにレイヤーのアクセス権限を追加する
         * @param {string} teamId 対象のチームID
         * @param {AddPermissionRequest} addPermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLayerToTeam(teamId: string, addPermissionRequest: AddPermissionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerWithPermission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLayerToTeam(teamId, addPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームからレイヤーのアクセス権限を削除する
         * @param {string} teamId 対象のチームID
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePermissionFromTeam(teamId: string, layerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePermissionFromTeam(teamId, layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームがアクセス権限を持つレイヤー一覧を返す
         * @param {string} teamId 対象のチームID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayersFromTeam(teamId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayersWithPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayersFromTeam(teamId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レイヤーへのアクセス権限を持つチーム一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsFromLayer(layerId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamsWithPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsFromLayer(layerId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームのレイヤーへのアクセス権限を更新する
         * @param {string} teamId 対象のチームID
         * @param {string} layerId 対象のレイヤーID
         * @param {UpdatePermissionRequest} updatePermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLayerOfTeam(teamId: string, layerId: string, updatePermissionRequest: UpdatePermissionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerWithPermission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLayerOfTeam(teamId, layerId, updatePermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayerPermissionApi - factory interface
 * @export
 */
export const LayerPermissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayerPermissionApiFp(configuration)
    return {
        /**
         * 
         * @summary チームにレイヤーのアクセス権限を追加する
         * @param {string} teamId 対象のチームID
         * @param {AddPermissionRequest} addPermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLayerToTeam(teamId: string, addPermissionRequest: AddPermissionRequest, options?: any): AxiosPromise<LayerWithPermission> {
            return localVarFp.addLayerToTeam(teamId, addPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームからレイヤーのアクセス権限を削除する
         * @param {string} teamId 対象のチームID
         * @param {string} layerId 対象のレイヤーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermissionFromTeam(teamId: string, layerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePermissionFromTeam(teamId, layerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームがアクセス権限を持つレイヤー一覧を返す
         * @param {string} teamId 対象のチームID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayersFromTeam(teamId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<LayersWithPermissionResponse> {
            return localVarFp.getLayersFromTeam(teamId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レイヤーへのアクセス権限を持つチーム一覧を返す
         * @param {string} layerId 対象のレイヤーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsFromLayer(layerId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<TeamsWithPermissionResponse> {
            return localVarFp.getTeamsFromLayer(layerId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームのレイヤーへのアクセス権限を更新する
         * @param {string} teamId 対象のチームID
         * @param {string} layerId 対象のレイヤーID
         * @param {UpdatePermissionRequest} updatePermissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLayerOfTeam(teamId: string, layerId: string, updatePermissionRequest: UpdatePermissionRequest, options?: any): AxiosPromise<LayerWithPermission> {
            return localVarFp.updateLayerOfTeam(teamId, layerId, updatePermissionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayerPermissionApi - object-oriented interface
 * @export
 * @class LayerPermissionApi
 * @extends {BaseAPI}
 */
export class LayerPermissionApi extends BaseAPI {
    /**
     * 
     * @summary チームにレイヤーのアクセス権限を追加する
     * @param {string} teamId 対象のチームID
     * @param {AddPermissionRequest} addPermissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerPermissionApi
     */
    public addLayerToTeam(teamId: string, addPermissionRequest: AddPermissionRequest, options?: any) {
        return LayerPermissionApiFp(this.configuration).addLayerToTeam(teamId, addPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームからレイヤーのアクセス権限を削除する
     * @param {string} teamId 対象のチームID
     * @param {string} layerId 対象のレイヤーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerPermissionApi
     */
    public deletePermissionFromTeam(teamId: string, layerId: string, options?: any) {
        return LayerPermissionApiFp(this.configuration).deletePermissionFromTeam(teamId, layerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームがアクセス権限を持つレイヤー一覧を返す
     * @param {string} teamId 対象のチームID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerPermissionApi
     */
    public getLayersFromTeam(teamId: string, page?: number, size?: number, sort?: string, options?: any) {
        return LayerPermissionApiFp(this.configuration).getLayersFromTeam(teamId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レイヤーへのアクセス権限を持つチーム一覧を返す
     * @param {string} layerId 対象のレイヤーID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerPermissionApi
     */
    public getTeamsFromLayer(layerId: string, page?: number, size?: number, sort?: string, options?: any) {
        return LayerPermissionApiFp(this.configuration).getTeamsFromLayer(layerId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームのレイヤーへのアクセス権限を更新する
     * @param {string} teamId 対象のチームID
     * @param {string} layerId 対象のレイヤーID
     * @param {UpdatePermissionRequest} updatePermissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerPermissionApi
     */
    public updateLayerOfTeam(teamId: string, layerId: string, updatePermissionRequest: UpdatePermissionRequest, options?: any) {
        return LayerPermissionApiFp(this.configuration).updateLayerOfTeam(teamId, layerId, updatePermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織のファイルストレージの使用量を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} [startTime] 開始日の指定
         * @param {string} [endTime] 終了日の指定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesMetricsFromOrganization: async (organizationId: string, startTime?: string, endTime?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFilesMetricsFromOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/files/metrics`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のレイヤーの使用量を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} [startTime] 開始日の指定
         * @param {string} [endTime] 終了日の指定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayersMetricsFromOrganization: async (organizationId: string, startTime?: string, endTime?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getLayersMetricsFromOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/layers/metrics`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織のファイルストレージの使用量を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} [startTime] 開始日の指定
         * @param {string} [endTime] 終了日の指定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesMetricsFromOrganization(organizationId: string, startTime?: string, endTime?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesMetricsFromOrganization(organizationId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のレイヤーの使用量を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} [startTime] 開始日の指定
         * @param {string} [endTime] 終了日の指定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLayersMetricsFromOrganization(organizationId: string, startTime?: string, endTime?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayersMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLayersMetricsFromOrganization(organizationId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織のファイルストレージの使用量を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} [startTime] 開始日の指定
         * @param {string} [endTime] 終了日の指定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesMetricsFromOrganization(organizationId: string, startTime?: string, endTime?: string, options?: any): AxiosPromise<FilesMetricsResponse> {
            return localVarFp.getFilesMetricsFromOrganization(organizationId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のレイヤーの使用量を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} [startTime] 開始日の指定
         * @param {string} [endTime] 終了日の指定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayersMetricsFromOrganization(organizationId: string, startTime?: string, endTime?: string, options?: any): AxiosPromise<LayersMetricsResponse> {
            return localVarFp.getLayersMetricsFromOrganization(organizationId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary 組織のファイルストレージの使用量を返す
     * @param {string} organizationId 対象の組織ID
     * @param {string} [startTime] 開始日の指定
     * @param {string} [endTime] 終了日の指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getFilesMetricsFromOrganization(organizationId: string, startTime?: string, endTime?: string, options?: any) {
        return MetricsApiFp(this.configuration).getFilesMetricsFromOrganization(organizationId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のレイヤーの使用量を返す
     * @param {string} organizationId 対象の組織ID
     * @param {string} [startTime] 開始日の指定
     * @param {string} [endTime] 終了日の指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getLayersMetricsFromOrganization(organizationId: string, startTime?: string, endTime?: string, options?: any) {
        return MetricsApiFp(this.configuration).getLayersMetricsFromOrganization(organizationId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgMembershipApi - axios parameter creator
 * @export
 */
export const OrgMembershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織にユーザーを追加する
         * @param {string} organizationId 対象の組織ID
         * @param {AddOrganizationMemberRequest} addOrganizationMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToOrganization: async (organizationId: string, addOrganizationMemberRequest: AddOrganizationMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addMemberToOrganization', 'organizationId', organizationId)
            // verify required parameter 'addOrganizationMemberRequest' is not null or undefined
            assertParamExists('addMemberToOrganization', 'addOrganizationMemberRequest', addOrganizationMemberRequest)
            const localVarPath = `/organizations/{organizationId}/members`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrganizationMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織からユーザーを削除する
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberFromOrganization: async (organizationId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteMemberFromOrganization', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMemberFromOrganization', 'userId', userId)
            const localVarPath = `/organizations/{organizationId}/members/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織に所属するユーザーを返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberOfOrganization: async (organizationId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getMemberOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMemberOfOrganization', 'userId', userId)
            const localVarPath = `/organizations/{organizationId}/members/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織に所属するユーザー一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] ユーザー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersFromOrganization: async (organizationId: string, page?: number, size?: number, sort?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getMembersFromOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/members`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現在のユーザーが所属する組織一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsFromCurrentUser: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織でのユーザーの役割を更新する
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {UpdateOrganizationMemberRequest} updateOrganizationMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberOfOrganization: async (organizationId: string, userId: string, updateOrganizationMemberRequest: UpdateOrganizationMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateMemberOfOrganization', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMemberOfOrganization', 'userId', userId)
            // verify required parameter 'updateOrganizationMemberRequest' is not null or undefined
            assertParamExists('updateMemberOfOrganization', 'updateOrganizationMemberRequest', updateOrganizationMemberRequest)
            const localVarPath = `/organizations/{organizationId}/members/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgMembershipApi - functional programming interface
 * @export
 */
export const OrgMembershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgMembershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 組織にユーザーを追加する
         * @param {string} organizationId 対象の組織ID
         * @param {AddOrganizationMemberRequest} addOrganizationMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToOrganization(organizationId: string, addOrganizationMemberRequest: AddOrganizationMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToOrganization(organizationId, addOrganizationMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織からユーザーを削除する
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemberFromOrganization(organizationId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMemberFromOrganization(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織に所属するユーザーを返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberOfOrganization(organizationId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberOfOrganization(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織に所属するユーザー一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] ユーザー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMembersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersFromOrganization(organizationId, page, size, sort, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 現在のユーザーが所属する組織一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsFromCurrentUser(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsWithMembershipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsFromCurrentUser(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織でのユーザーの役割を更新する
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {UpdateOrganizationMemberRequest} updateOrganizationMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberOfOrganization(organizationId: string, userId: string, updateOrganizationMemberRequest: UpdateOrganizationMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberOfOrganization(organizationId, userId, updateOrganizationMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrgMembershipApi - factory interface
 * @export
 */
export const OrgMembershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgMembershipApiFp(configuration)
    return {
        /**
         * 
         * @summary 組織にユーザーを追加する
         * @param {string} organizationId 対象の組織ID
         * @param {AddOrganizationMemberRequest} addOrganizationMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToOrganization(organizationId: string, addOrganizationMemberRequest: AddOrganizationMemberRequest, options?: any): AxiosPromise<OrganizationMember> {
            return localVarFp.addMemberToOrganization(organizationId, addOrganizationMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織からユーザーを削除する
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberFromOrganization(organizationId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMemberFromOrganization(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織に所属するユーザーを返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberOfOrganization(organizationId: string, userId: string, options?: any): AxiosPromise<OrganizationMember> {
            return localVarFp.getMemberOfOrganization(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織に所属するユーザー一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] ユーザー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any): AxiosPromise<OrganizationMembersResponse> {
            return localVarFp.getMembersFromOrganization(organizationId, page, size, sort, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現在のユーザーが所属する組織一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsFromCurrentUser(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<OrganizationsWithMembershipResponse> {
            return localVarFp.getOrganizationsFromCurrentUser(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織でのユーザーの役割を更新する
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {UpdateOrganizationMemberRequest} updateOrganizationMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberOfOrganization(organizationId: string, userId: string, updateOrganizationMemberRequest: UpdateOrganizationMemberRequest, options?: any): AxiosPromise<OrganizationMember> {
            return localVarFp.updateMemberOfOrganization(organizationId, userId, updateOrganizationMemberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgMembershipApi - object-oriented interface
 * @export
 * @class OrgMembershipApi
 * @extends {BaseAPI}
 */
export class OrgMembershipApi extends BaseAPI {
    /**
     * 
     * @summary 組織にユーザーを追加する
     * @param {string} organizationId 対象の組織ID
     * @param {AddOrganizationMemberRequest} addOrganizationMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgMembershipApi
     */
    public addMemberToOrganization(organizationId: string, addOrganizationMemberRequest: AddOrganizationMemberRequest, options?: any) {
        return OrgMembershipApiFp(this.configuration).addMemberToOrganization(organizationId, addOrganizationMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織からユーザーを削除する
     * @param {string} organizationId 対象の組織ID
     * @param {string} userId 対象のユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgMembershipApi
     */
    public deleteMemberFromOrganization(organizationId: string, userId: string, options?: any) {
        return OrgMembershipApiFp(this.configuration).deleteMemberFromOrganization(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織に所属するユーザーを返す
     * @param {string} organizationId 対象の組織ID
     * @param {string} userId 対象のユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgMembershipApi
     */
    public getMemberOfOrganization(organizationId: string, userId: string, options?: any) {
        return OrgMembershipApiFp(this.configuration).getMemberOfOrganization(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織に所属するユーザー一覧を返す
     * @param {string} organizationId 対象の組織ID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {string} [name] ユーザー名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgMembershipApi
     */
    public getMembersFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any) {
        return OrgMembershipApiFp(this.configuration).getMembersFromOrganization(organizationId, page, size, sort, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現在のユーザーが所属する組織一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgMembershipApi
     */
    public getOrganizationsFromCurrentUser(page?: number, size?: number, sort?: string, options?: any) {
        return OrgMembershipApiFp(this.configuration).getOrganizationsFromCurrentUser(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織でのユーザーの役割を更新する
     * @param {string} organizationId 対象の組織ID
     * @param {string} userId 対象のユーザーID
     * @param {UpdateOrganizationMemberRequest} updateOrganizationMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgMembershipApi
     */
    public updateMemberOfOrganization(organizationId: string, userId: string, updateOrganizationMemberRequest: UpdateOrganizationMemberRequest, options?: any) {
        return OrgMembershipApiFp(this.configuration).updateMemberOfOrganization(organizationId, userId, updateOrganizationMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 組織作成後にすぐに使い始めることができるデフォルトセットを作ることができる。  デフォルトセット - デフォルトレイヤー - デフォルトチーム - デフォルトレイヤーとデフォルトチームとの間の管理権限 - 操作ユーザーのデフォルトチームへの所属 
         * @summary 組織を作成する
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (createOrganizationRequest: CreateOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationRequest' is not null or undefined
            assertParamExists('createOrganization', 'createOrganizationRequest', createOrganizationRequest)
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織を返す
         * @param {string} organizationId 対象の組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織を更新する
         * @param {string} organizationId 対象の組織ID
         * @param {UpdateOrganizationRequest} updateOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (organizationId: string, updateOrganizationRequest: UpdateOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganization', 'organizationId', organizationId)
            // verify required parameter 'updateOrganizationRequest' is not null or undefined
            assertParamExists('updateOrganization', 'updateOrganizationRequest', updateOrganizationRequest)
            const localVarPath = `/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織の無効化フラグを更新をする
         * @param {string} organizationId 対象の組織ID
         * @param {UpdateOrganizationDeactivationRequest} updateOrganizationDeactivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationDeactivation: async (organizationId: string, updateOrganizationDeactivationRequest: UpdateOrganizationDeactivationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationDeactivation', 'organizationId', organizationId)
            // verify required parameter 'updateOrganizationDeactivationRequest' is not null or undefined
            assertParamExists('updateOrganizationDeactivation', 'updateOrganizationDeactivationRequest', updateOrganizationDeactivationRequest)
            const localVarPath = `/organizations/{organizationId}/deactivation`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationDeactivationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 組織作成後にすぐに使い始めることができるデフォルトセットを作ることができる。  デフォルトセット - デフォルトレイヤー - デフォルトチーム - デフォルトレイヤーとデフォルトチームとの間の管理権限 - 操作ユーザーのデフォルトチームへの所属 
         * @summary 組織を作成する
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(createOrganizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織を返す
         * @param {string} organizationId 対象の組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織を更新する
         * @param {string} organizationId 対象の組織ID
         * @param {UpdateOrganizationRequest} updateOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(organizationId: string, updateOrganizationRequest: UpdateOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(organizationId, updateOrganizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織の無効化フラグを更新をする
         * @param {string} organizationId 対象の組織ID
         * @param {UpdateOrganizationDeactivationRequest} updateOrganizationDeactivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationDeactivation(organizationId: string, updateOrganizationDeactivationRequest: UpdateOrganizationDeactivationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationDeactivation(organizationId, updateOrganizationDeactivationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 組織作成後にすぐに使い始めることができるデフォルトセットを作ることができる。  デフォルトセット - デフォルトレイヤー - デフォルトチーム - デフォルトレイヤーとデフォルトチームとの間の管理権限 - 操作ユーザーのデフォルトチームへの所属 
         * @summary 組織を作成する
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: any): AxiosPromise<Organization> {
            return localVarFp.createOrganization(createOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織を返す
         * @param {string} organizationId 対象の組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(organizationId: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.getOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<OrganizationsResponse> {
            return localVarFp.getOrganizations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織を更新する
         * @param {string} organizationId 対象の組織ID
         * @param {UpdateOrganizationRequest} updateOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(organizationId: string, updateOrganizationRequest: UpdateOrganizationRequest, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganization(organizationId, updateOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織の無効化フラグを更新をする
         * @param {string} organizationId 対象の組織ID
         * @param {UpdateOrganizationDeactivationRequest} updateOrganizationDeactivationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationDeactivation(organizationId: string, updateOrganizationDeactivationRequest: UpdateOrganizationDeactivationRequest, options?: any): AxiosPromise<Organization> {
            return localVarFp.updateOrganizationDeactivation(organizationId, updateOrganizationDeactivationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 組織作成後にすぐに使い始めることができるデフォルトセットを作ることができる。  デフォルトセット - デフォルトレイヤー - デフォルトチーム - デフォルトレイヤーとデフォルトチームとの間の管理権限 - 操作ユーザーのデフォルトチームへの所属 
     * @summary 組織を作成する
     * @param {CreateOrganizationRequest} createOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public createOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: any) {
        return OrganizationApiFp(this.configuration).createOrganization(createOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織を返す
     * @param {string} organizationId 対象の組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganization(organizationId: string, options?: any) {
        return OrganizationApiFp(this.configuration).getOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizations(page?: number, size?: number, sort?: string, options?: any) {
        return OrganizationApiFp(this.configuration).getOrganizations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織を更新する
     * @param {string} organizationId 対象の組織ID
     * @param {UpdateOrganizationRequest} updateOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public updateOrganization(organizationId: string, updateOrganizationRequest: UpdateOrganizationRequest, options?: any) {
        return OrganizationApiFp(this.configuration).updateOrganization(organizationId, updateOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織の無効化フラグを更新をする
     * @param {string} organizationId 対象の組織ID
     * @param {UpdateOrganizationDeactivationRequest} updateOrganizationDeactivationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public updateOrganizationDeactivation(organizationId: string, updateOrganizationDeactivationRequest: UpdateOrganizationDeactivationRequest, options?: any) {
        return OrganizationApiFp(this.configuration).updateOrganizationDeactivation(organizationId, updateOrganizationDeactivationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary チームを作成する
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (createTeamRequest: CreateTeamRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTeamRequest' is not null or undefined
            assertParamExists('createTeam', 'createTeamRequest', createTeamRequest)
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームを削除する
         * @param {string} teamId 対象のチームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam: async (teamId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deleteTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームを返す
         * @param {string} teamId 対象のチームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam: async (teamId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チーム一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] チーム名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams: async (page?: number, size?: number, sort?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のチーム一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] チーム名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsFromOrganization: async (organizationId: string, page?: number, size?: number, sort?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTeamsFromOrganization', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/teams`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームを更新する
         * @param {string} teamId 対象のチームID
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (teamId: string, updateTeamRequest: UpdateTeamRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateTeam', 'teamId', teamId)
            // verify required parameter 'updateTeamRequest' is not null or undefined
            assertParamExists('updateTeam', 'updateTeamRequest', updateTeamRequest)
            const localVarPath = `/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary チームを作成する
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(createTeamRequest: CreateTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(createTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームを削除する
         * @param {string} teamId 対象のチームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeam(teamId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームを返す
         * @param {string} teamId 対象のチームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeam(teamId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チーム一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] チーム名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeams(page?: number, size?: number, sort?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeams(page, size, sort, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のチーム一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] チーム名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsFromOrganization(organizationId, page, size, sort, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームを更新する
         * @param {string} teamId 対象のチームID
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(teamId: string, updateTeamRequest: UpdateTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(teamId, updateTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * 
         * @summary チームを作成する
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(createTeamRequest: CreateTeamRequest, options?: any): AxiosPromise<Team> {
            return localVarFp.createTeam(createTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームを削除する
         * @param {string} teamId 対象のチームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(teamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームを返す
         * @param {string} teamId 対象のチームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam(teamId: string, options?: any): AxiosPromise<Team> {
            return localVarFp.getTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チーム一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] チーム名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams(page?: number, size?: number, sort?: string, name?: string, options?: any): AxiosPromise<TeamsResponse> {
            return localVarFp.getTeams(page, size, sort, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のチーム一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] チーム名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any): AxiosPromise<TeamsResponse> {
            return localVarFp.getTeamsFromOrganization(organizationId, page, size, sort, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームを更新する
         * @param {string} teamId 対象のチームID
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(teamId: string, updateTeamRequest: UpdateTeamRequest, options?: any): AxiosPromise<Team> {
            return localVarFp.updateTeam(teamId, updateTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * 
     * @summary チームを作成する
     * @param {CreateTeamRequest} createTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public createTeam(createTeamRequest: CreateTeamRequest, options?: any) {
        return TeamApiFp(this.configuration).createTeam(createTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームを削除する
     * @param {string} teamId 対象のチームID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public deleteTeam(teamId: string, options?: any) {
        return TeamApiFp(this.configuration).deleteTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームを返す
     * @param {string} teamId 対象のチームID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeam(teamId: string, options?: any) {
        return TeamApiFp(this.configuration).getTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チーム一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {string} [name] チーム名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeams(page?: number, size?: number, sort?: string, name?: string, options?: any) {
        return TeamApiFp(this.configuration).getTeams(page, size, sort, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のチーム一覧を返す
     * @param {string} organizationId 対象の組織ID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {string} [name] チーム名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeamsFromOrganization(organizationId: string, page?: number, size?: number, sort?: string, name?: string, options?: any) {
        return TeamApiFp(this.configuration).getTeamsFromOrganization(organizationId, page, size, sort, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームを更新する
     * @param {string} teamId 対象のチームID
     * @param {UpdateTeamRequest} updateTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public updateTeam(teamId: string, updateTeamRequest: UpdateTeamRequest, options?: any) {
        return TeamApiFp(this.configuration).updateTeam(teamId, updateTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamMembershipApi - axios parameter creator
 * @export
 */
export const TeamMembershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary チームにユーザーを追加する
         * @param {string} teamId 対象のチームID
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToTeam: async (teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addMemberToTeam', 'teamId', teamId)
            // verify required parameter 'addTeamMemberRequest' is not null or undefined
            assertParamExists('addMemberToTeam', 'addTeamMemberRequest', addTeamMemberRequest)
            const localVarPath = `/teams/{teamId}/members`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームからユーザーを削除する
         * @param {string} teamId 対象のチームID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberFromTeam: async (teamId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deleteMemberFromTeam', 'teamId', teamId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMemberFromTeam', 'userId', userId)
            const localVarPath = `/teams/{teamId}/members/{userId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームに所属するユーザー一覧を返す
         * @param {string} teamId 対象のチームID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersFromTeam: async (teamId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getMembersFromTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}/members`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 組織のメンバーが所属するチーム一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsFromOrganizationMember: async (organizationId: string, userId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTeamsFromOrganizationMember', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTeamsFromOrganizationMember', 'userId', userId)
            const localVarPath = `/organizations/{organizationId}/members/{userId}/teams`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [getTeamsFromOrganizationMember](#operation/getTeamsFromOrganizationMember)に移行
         * @summary ユーザーが所属するチーム一覧を返す
         * @param {string} userId 対象のユーザーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTeamsFromUser: async (userId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTeamsFromUser', 'userId', userId)
            const localVarPath = `/users/{userId}/teams`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamMembershipApi - functional programming interface
 * @export
 */
export const TeamMembershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamMembershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary チームにユーザーを追加する
         * @param {string} teamId 対象のチームID
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToTeam(teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToTeam(teamId, addTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームからユーザーを削除する
         * @param {string} teamId 対象のチームID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemberFromTeam(teamId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMemberFromTeam(teamId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームに所属するユーザー一覧を返す
         * @param {string} teamId 対象のチームID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersFromTeam(teamId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersFromTeam(teamId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 組織のメンバーが所属するチーム一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsFromOrganizationMember(organizationId: string, userId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsFromOrganizationMember(organizationId, userId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [getTeamsFromOrganizationMember](#operation/getTeamsFromOrganizationMember)に移行
         * @summary ユーザーが所属するチーム一覧を返す
         * @param {string} userId 対象のユーザーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getTeamsFromUser(userId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsFromUser(userId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamMembershipApi - factory interface
 * @export
 */
export const TeamMembershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamMembershipApiFp(configuration)
    return {
        /**
         * 
         * @summary チームにユーザーを追加する
         * @param {string} teamId 対象のチームID
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToTeam(teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options?: any): AxiosPromise<User> {
            return localVarFp.addMemberToTeam(teamId, addTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームからユーザーを削除する
         * @param {string} teamId 対象のチームID
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberFromTeam(teamId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMemberFromTeam(teamId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームに所属するユーザー一覧を返す
         * @param {string} teamId 対象のチームID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersFromTeam(teamId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.getMembersFromTeam(teamId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 組織のメンバーが所属するチーム一覧を返す
         * @param {string} organizationId 対象の組織ID
         * @param {string} userId 対象のユーザーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsFromOrganizationMember(organizationId: string, userId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<TeamsResponse> {
            return localVarFp.getTeamsFromOrganizationMember(organizationId, userId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * [getTeamsFromOrganizationMember](#operation/getTeamsFromOrganizationMember)に移行
         * @summary ユーザーが所属するチーム一覧を返す
         * @param {string} userId 対象のユーザーID
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTeamsFromUser(userId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<TeamsResponse> {
            return localVarFp.getTeamsFromUser(userId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamMembershipApi - object-oriented interface
 * @export
 * @class TeamMembershipApi
 * @extends {BaseAPI}
 */
export class TeamMembershipApi extends BaseAPI {
    /**
     * 
     * @summary チームにユーザーを追加する
     * @param {string} teamId 対象のチームID
     * @param {AddTeamMemberRequest} addTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembershipApi
     */
    public addMemberToTeam(teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options?: any) {
        return TeamMembershipApiFp(this.configuration).addMemberToTeam(teamId, addTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームからユーザーを削除する
     * @param {string} teamId 対象のチームID
     * @param {string} userId 対象のユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembershipApi
     */
    public deleteMemberFromTeam(teamId: string, userId: string, options?: any) {
        return TeamMembershipApiFp(this.configuration).deleteMemberFromTeam(teamId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームに所属するユーザー一覧を返す
     * @param {string} teamId 対象のチームID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembershipApi
     */
    public getMembersFromTeam(teamId: string, page?: number, size?: number, sort?: string, options?: any) {
        return TeamMembershipApiFp(this.configuration).getMembersFromTeam(teamId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 組織のメンバーが所属するチーム一覧を返す
     * @param {string} organizationId 対象の組織ID
     * @param {string} userId 対象のユーザーID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMembershipApi
     */
    public getTeamsFromOrganizationMember(organizationId: string, userId: string, page?: number, size?: number, sort?: string, options?: any) {
        return TeamMembershipApiFp(this.configuration).getTeamsFromOrganizationMember(organizationId, userId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [getTeamsFromOrganizationMember](#operation/getTeamsFromOrganizationMember)に移行
     * @summary ユーザーが所属するチーム一覧を返す
     * @param {string} userId 対象のユーザーID
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TeamMembershipApi
     */
    public getTeamsFromUser(userId: string, page?: number, size?: number, sort?: string, options?: any) {
        return TeamMembershipApiFp(this.configuration).getTeamsFromUser(userId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ユーザーを作成する
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを削除する
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現在のユーザーを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを返す
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] ユーザー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (page?: number, size?: number, sort?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {string} userId 対象のユーザーID
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUserRequest: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ユーザーを作成する
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを削除する
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 現在のユーザーを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを返す
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] ユーザー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(page?: number, size?: number, sort?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(page, size, sort, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {string} userId 対象のユーザーID
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary ユーザーを作成する
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<MyUserResponse> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを削除する
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現在のユーザーを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<MyUserResponse> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを返す
         * @param {string} userId 対象のユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー一覧を返す
         * @param {number} [page] ページ番号
         * @param {number} [size] 取得件数
         * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
         * @param {string} [name] ユーザー名での絞り込み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(page?: number, size?: number, sort?: string, name?: string, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.getUsers(page, size, sort, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {string} userId 対象のユーザーID
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userId, updateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary ユーザーを作成する
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: any) {
        return UserApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを削除する
     * @param {string} userId 対象のユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userId: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現在のユーザーを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUser(options?: any) {
        return UserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを返す
     * @param {string} userId 対象のユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: string, options?: any) {
        return UserApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー一覧を返す
     * @param {number} [page] ページ番号
     * @param {number} [size] 取得件数
     * @param {string} [sort] ソート順。日時でソートする場合は createdAt や -createdAt を指定する
     * @param {string} [name] ユーザー名での絞り込み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(page?: number, size?: number, sort?: string, name?: string, options?: any) {
        return UserApiFp(this.configuration).getUsers(page, size, sort, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを更新する
     * @param {string} userId 対象のユーザーID
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).updateUser(userId, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


