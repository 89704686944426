import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
  useCallback,
} from "react";
import { useAsync } from "react-use";
import { Organization } from "../../generated/api";
import {
  getCurrentOrganizationFromLocalStorage,
  setCurrentOrganizationToLocalStorage,
} from "../../repositories/organization";
import { fetchCurrentUserOrganizations } from "../../repositories/user";
import { useFetchOrganization } from "../http/organization";
import { useAuth } from "./AuthContext";

type CurrentOrganizationState = Organization | undefined;
type CurrentOrganizationDispatch = Dispatch<
  SetStateAction<CurrentOrganizationState>
>;

const CurrentOrganizationStateContext =
  createContext<CurrentOrganizationState | null>(null);
const CurrentOrganizationDispatchContext =
  createContext<CurrentOrganizationDispatch | null>(null);

const CurrentOrganizationProvider = ({ children }: { children: ReactNode }) => {
  const [organization, setOrganization] = useState<CurrentOrganizationState>();
  const [user] = useAuth();

  // localStorageを見て初期の組織を設定する
  useAsync(async () => {
    if (!user?.id) return;
    const localStorageOrg = getCurrentOrganizationFromLocalStorage(user.id);

    const { data: organizationsResponse } =
      await fetchCurrentUserOrganizations();
    const organizations = organizationsResponse?.data;

    // localStorageに保存済みの組織がある場合は、現在の所属状況を取得して最新のデータに更新する
    if (localStorageOrg) {
      const org = organizations.find((org) => org.id === localStorageOrg.id);
      if (org) {
        setOrganization(org);
        setCurrentOrganizationToLocalStorage(organizations[0], user.id);
        return;
      }
    }

    // localStorage未設定の場合は先頭の組織を初期値としてセット
    if (organizations.length > 0) {
      setOrganization(organizations[0]);
      setCurrentOrganizationToLocalStorage(organizations[0], user.id);
      return;
    }

    // 存在しない場合はundefinedを設定する
    setOrganization(undefined);
  }, [user?.id]);

  return (
    <CurrentOrganizationStateContext.Provider value={organization}>
      <CurrentOrganizationDispatchContext.Provider value={setOrganization}>
        {children}
      </CurrentOrganizationDispatchContext.Provider>
    </CurrentOrganizationStateContext.Provider>
  );
};

const useCurrentOrganizationState = (): CurrentOrganizationState => {
  const currentOrganization = useContext(CurrentOrganizationStateContext);

  // 最新のデータを使用する
  const { data: { data: organization } = {} } = useFetchOrganization(
    currentOrganization?.id
  );

  if (currentOrganization === null) {
    throw new Error(
      "No context provided: useCurrentOrganizationState() can only be used within a CurrentOrganizationProvider"
    );
  }
  return organization ?? currentOrganization;
};

const useCurrentOrganizationDispatch = (): ((e: Organization) => void) => {
  const setOrganization = useContext(CurrentOrganizationDispatchContext);
  const [user] = useAuth();

  if (setOrganization === null) {
    throw new Error(
      "No context provided: useCurrentOrganizationDispatch() can only be used within a CurrentOrganizationProvider"
    );
  }

  const setCurrentOrganization = useCallback(
    (e: Organization) => {
      setOrganization(e);
      setCurrentOrganizationToLocalStorage(e, user?.id);
    },
    [setOrganization, user]
  );

  return setCurrentOrganization;
};

export {
  CurrentOrganizationProvider,
  useCurrentOrganizationState,
  useCurrentOrganizationDispatch,
};
