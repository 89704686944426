import { LocalStorageKeys } from "../constants/LocalStorageKeys";
import {
  Organization,
  OrganizationApiFactory,
  OrgMembershipApiFactory,
  TeamMembershipApiFactory,
  CreateOrganizationRequest,
  AddOrganizationMemberRequest,
  User,
  UserRole,
  UpdateOrganizationRequest,
  Team,
} from "../generated/api";
import { isOrganization } from "../helpers/isModel";
import { restore, save } from "../helpers/localStorage";
import { createConfig } from "./configuration";
import { addTeamMember, deleteTeamMember } from "./team";

export const fetchOrganizations = async (
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined
) => {
  const config = await createConfig();
  const res = await OrganizationApiFactory(config).getOrganizations(
    page,
    size,
    sort
  );
  return res;
};

export const fetchOrganization = async (organizationId: Organization["id"]) => {
  const config = await createConfig();
  const res = await OrganizationApiFactory(config).getOrganization(
    organizationId
  );
  return res;
};

export type CreateOrganizationParams = CreateOrganizationRequest;
export const createOrganization = async (params: CreateOrganizationParams) => {
  const config = await createConfig();
  const res = await OrganizationApiFactory(config).createOrganization(params);
  return res;
};

export const updateOrganization = async (
  organizationId: Organization["id"],
  params: UpdateOrganizationRequest
) => {
  const config = await createConfig();
  const res = await OrganizationApiFactory(config).updateOrganization(
    organizationId,
    params
  );
  return res;
};

export const fetchOrganizationMembers = async (
  organizationId: Organization["id"],
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined,
  name?: string | undefined
) => {
  const config = await createConfig();
  const res = await OrgMembershipApiFactory(config).getMembersFromOrganization(
    organizationId,
    page,
    size,
    sort,
    name
  );
  return res;
};

export const addOrganizationMember = async (
  organizationId: Organization["id"],
  params: AddOrganizationMemberRequest
) => {
  const config = await createConfig();
  const res = await OrgMembershipApiFactory(config).addMemberToOrganization(
    organizationId,
    params
  );
  return res;
};

export const deleteOrganizationMember = async (
  organizationId: Organization["id"],
  userId: User["id"]
) => {
  const config = await createConfig();
  const res = await OrgMembershipApiFactory(
    config
  ).deleteMemberFromOrganization(organizationId, userId);
  return res;
};

export const updateOrganizationMemberRole = async (
  organizationId: Organization["id"],
  userId: User["id"],
  role: UserRole
) => {
  const config = await createConfig();
  const res = await OrgMembershipApiFactory(config).updateMemberOfOrganization(
    organizationId,
    userId,
    {
      role,
    }
  );
  return res;
};

export const updateOrganizationMember = async (
  organizationId: Organization["id"],
  userId: User["id"],
  params: { role?: UserRole; teamIds?: Team["id"][] }
) => {
  const { role, teamIds } = params;
  if (role) {
    await updateOrganizationMemberRole(organizationId, userId, role);
  }
  if (teamIds) {
    const currentTeams = await fetchAllTeamsFromOrganizationMember(
      organizationId,
      userId
    );

    // 所属していなく、teamIdsに含まれているチームを追加
    for (const teamId of teamIds) {
      const exists = currentTeams.find((team) => team.id === teamId);
      if (!exists) {
        await addTeamMember(teamId, { userId });
      }
    }

    // 所属していて、teamIdsに含まれていないチームを削除
    for (const team of currentTeams) {
      const exists = teamIds.find((teamId) => team.id === teamId);
      if (!exists) {
        await deleteTeamMember(team.id, userId);
      }
    }
  }
};

export const fetchTeamsFromOrganizationMember = async (
  organizationId: Organization["id"],
  userId: User["id"],
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined
) => {
  const config = await createConfig();
  const res = await TeamMembershipApiFactory(
    config
  ).getTeamsFromOrganizationMember(organizationId, userId, page, size, sort);
  return res;
};

export const fetchAllTeamsFromOrganizationMember = async (
  organizationId: Organization["id"],
  userId: User["id"]
) => {
  const config = await createConfig();

  const teams: Team[] = [];

  for (let page = 1; ; page++) {
    const {
      data: { data, pager },
    } = await TeamMembershipApiFactory(config).getTeamsFromOrganizationMember(
      organizationId,
      userId,
      page,
      100,
      "createdAt"
    );

    if (!data || !pager) break;

    teams.push(...data);

    if (!pager.hasNextPage) break;
  }

  return teams;
};

const addSuffix = (base: string, suffix?: string) =>
  `${base}${suffix ? `:${suffix}` : ""}`;
/**
 * localStorageから選択中の組織を取得する。
 */
export const getCurrentOrganizationFromLocalStorage = (suffix?: string) => {
  const organization = restore(
    addSuffix(LocalStorageKeys.currentOrganization, suffix)
  );
  if (organization && isOrganization(organization)) {
    return organization;
  }
  return undefined;
};
/**
 * localStorageに組織を設定する
 */
export const setCurrentOrganizationToLocalStorage = (
  organization: Organization,
  suffix?: string
) => {
  save(addSuffix(LocalStorageKeys.currentOrganization, suffix), organization);
};
