import { AxiosError } from "axios";

export const isString = (arg: unknown): arg is string =>
  typeof arg === "string";

export const isBoolean = (arg: unknown): arg is boolean =>
  typeof arg === "boolean";

export const isNumber = (arg: unknown): arg is number =>
  typeof arg === "number";

export const isNumberString = (arg: unknown): boolean =>
  isString(arg) && /^\s*-?(\d+\.?|\d*\.\d+)\s*$/.test(arg);

// axiosのisAxiosErrorはnamed import出来ないため自前実装
export const isAxiosError = (arg: unknown): arg is AxiosError => {
  if (typeof arg === "object" && arg && "isAxiosError" in arg) {
    return !!(arg as AxiosError).isAxiosError;
  }
  return false;
};

export const isUndefined = (arg: unknown): arg is undefined => {
  return arg === undefined;
};

export const isNullOrUndefined = (arg: unknown): arg is null | undefined => {
  return arg === null || arg === undefined;
};

export const isRecord = (
  arg: unknown
): arg is Record<string | number | symbol, unknown> => {
  if (typeof arg !== "object") return false;
  if (arg === null) return false;
  if (Array.isArray(arg)) return false;
  return true;
};
