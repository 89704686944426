import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RoutePaths } from "../../constants/RoutePaths";
import { useAuth } from "../../hooks/contexts/AuthContext";

export const PrivatePage = () => {
  const [auth] = useAuth();
  const location = useLocation();

  return auth ? (
    <Outlet />
  ) : (
    <Navigate replace to={RoutePaths.LOGIN} state={{ from: location }} />
  );
};
