import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { translateAmplifyErrorMessage } from "../helpers/amplify";

type LoginType = (username: string, password: string) => Promise<CognitoUser>;
/**
 * ログイン処理
 */
export const login: LoginType = async (username: string, password: string) => {
  try {
    const result: CognitoUser = await Auth.signIn(username, password);
    return result;
  } catch (e) {
    throw translateAmplifyErrorMessage(e);
  }
};

/**
 * ログアウト処理
 */
export const logout = async () => {
  try {
    await Auth.signOut();
  } catch (e) {
    throw translateAmplifyErrorMessage(e);
  }
};

type GetCurrentUserType = () => Promise<CognitoUser | undefined>;
/**
 * ログイン中のユーザー取得
 */
export const getCurrentUser: GetCurrentUserType = async () => {
  try {
    const user: CognitoUser | undefined =
      await Auth.currentAuthenticatedUser().catch(() => undefined);
    return user;
  } catch (e) {
    throw translateAmplifyErrorMessage(e);
  }
};

/**
 * ログイン中のユーザーのパスワードを変更
 */
export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.changePassword(user, oldPassword, newPassword);
    return data;
  } catch (e) {
    throw translateAmplifyErrorMessage(e);
  }
};

/**
 * credentialsを取得
 */
export const getCredentials = async () => {
  const session = await Auth.currentSession();
  const region = process.env.REACT_APP_AWS_COGNITO_REGION || "";
  const userPoolId = process.env.REACT_APP_AWS_USER_POOL_ID || "";
  const identityPoolId =
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || "";
  const providerName = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;

  return fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region }),
    identityPoolId,
    logins: {
      [providerName]: session.getIdToken().getJwtToken(),
    },
  });
};
