import {
  UserApiFactory,
  OrgMembershipApiFactory,
  CreateUserRequest,
  UpdateUserRequest,
} from "../generated/api";
import { createConfig } from "./configuration";

export const fetchUsers = async (
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined,
  name?: string | undefined
) => {
  const config = await createConfig();
  const res = await UserApiFactory(config).getUsers(page, size, sort, name);
  return res;
};

export const fetchUser = async (userId: string) => {
  const config = await createConfig();
  const res = await UserApiFactory(config).getUser(userId);
  return res;
};

export const fetchCurrentUser = async () => {
  const config = await createConfig();
  const res = await UserApiFactory(config).getCurrentUser();
  return res;
};

export const fetchCurrentUserOrganizations = async (
  page?: number | undefined,
  size?: number | undefined,
  sort?: string | undefined
) => {
  const config = await createConfig();
  const res = await OrgMembershipApiFactory(
    config
  ).getOrganizationsFromCurrentUser(page, size, sort);
  return res;
};

export const createUser = async (
  username: string,
  password: string,
  email: string,
  displayName?: string
) => {
  const config = await createConfig({ noAuth: true });
  const request: CreateUserRequest = {
    displayName,
    email,
    name: username,
    password,
  };

  const res = await UserApiFactory(config).createUser(request);

  return res;
};

type UpdateUserProfileParams = Omit<UpdateUserRequest, "name">;

/**
 * name以外のUser情報を更新する
 */
export const updateUserProfile = async (
  userId: string,
  params: UpdateUserProfileParams
) => {
  const config = await createConfig();
  const res = await UserApiFactory(config).updateUser(userId, params);
  return res;
};

type UpdateUserNameParams = Required<Pick<UpdateUserRequest, "name">>;

/**
 * ユーザー名を更新する
 */
export const updateUserName = async (
  userId: string,
  params: UpdateUserNameParams
) => {
  const config = await createConfig();
  const res = await UserApiFactory(config).updateUser(userId, params);
  return res;
};
