import { isString } from "./is";

// eslint-disable-next-line @typescript-eslint/ban-types
export const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
  obj: X,
  key: Y
): obj is X & Record<Y, unknown> => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const hasOwnStringProperty = <X extends {}, Y extends PropertyKey>(
  obj: X,
  key: Y
): obj is X & Record<Y, string> => {
  return hasOwnProperty(obj, key) && isString(obj[key]);
};
