import { useOrganization } from "../../hooks/contexts/OrganizationContext";

type Props = {
  className?: string;
};

export const OrganizationName = (props: Props) => {
  const { className } = props;
  const organization = useOrganization();

  const name = organization.name || "";

  return <span className={className}>{name}</span>;
};
