import { StrictMode } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import App from "./App";
import { setUpAmplifyI18n } from "./helpers/amplify";

import "fomantic-ui-css/semantic.min.css";
import "leaflet/dist/leaflet.css";
import "react-spring-bottom-sheet/dist/style.css";
import "./index.scss";
import "./styles/leaflet/custom_leaflet.scss";
import "./styles/hec-eye-icons/css/hec-eye-icons.css";

// Cognitoのエラーメッセージの日本語対応
setUpAmplifyI18n();

render(
  <StrictMode>
    <BrowserRouter>
      <SWRConfig>
        <App />
      </SWRConfig>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
