import { useCallback } from "react";
import { Dropdown } from "semantic-ui-react";
import { Icons } from "../../../constants/Icons";
import { CurrentOrganizationName } from "../../../consumers/organization/CurrentOrganizationName";
import {
  useCurrentOrganizationState,
  useCurrentOrganizationDispatch,
} from "../../../hooks/contexts/CurrentOrganizationContext";
import { useFetchCurrentUserOrganizations } from "../../../hooks/http/user";

export const OrganizationDropdown = () => {
  const { data: { data: organizationsResponse } = {} } =
    useFetchCurrentUserOrganizations();
  const organizations = organizationsResponse?.data;

  const organization = useCurrentOrganizationState();
  const setOrganization = useCurrentOrganizationDispatch();

  const onClick = useCallback(
    (_, { value }) => {
      const org = organizations?.find((o) => o.id === value);
      if (org) {
        setOrganization(org);
      }
    },
    [organizations, setOrganization]
  );

  if (!organization || !organizations) return <></>;
  return (
    <Dropdown
      floating
      item
      trigger={<CurrentOrganizationName className="ui label orange" />}
    >
      <Dropdown.Menu>
        <Dropdown.Header icon={Icons.ORGANIZATION} content="組織切り替え" />
        {organizations?.map((org) => {
          return (
            <Dropdown.Item
              key={org.id}
              text={org.name}
              value={org.id}
              onClick={onClick}
              disabled={organization.id === org.id}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
