import { Link } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import { OrganizationDropdown } from "./OrganizationDropdown";
import { UserDropdown } from "./UserDropdown";
import styles from "./HeaderMenu.module.scss";

export const HeaderMenu = () => {
  return (
    <Menu fixed="top" className={styles.root}>
      <Menu.Item as={Link} to="/">
        <Image className={styles.logo} src="/hec-eye_logo.png" />
      </Menu.Item>
      <Menu.Menu position="right">
        <OrganizationDropdown />
        <UserDropdown />
      </Menu.Menu>
    </Menu>
  );
};
