/* This file is auto generated by icon/build.js; DO NOT EDIT. */

export const HecEyeIcons = {
  AED_ICON: "he-icon he-icon-aed",
  AIRPORT_ICON: "he-icon he-icon-airport",
  AMUSEMENT_PARK_ICON: "he-icon he-icon-amusement-park",
  AQUARIUM_ICON: "he-icon he-icon-aquarium",
  BICYCLE_ICON: "he-icon he-icon-bicycle",
  BLOCKED_ICON: "he-icon he-icon-blocked",
  BUILDING_ICON: "he-icon he-icon-building",
  BUS_ICON: "he-icon he-icon-bus",
  BUS_STOP_ICON: "he-icon he-icon-bus-stop",
  CAMP_ICON: "he-icon he-icon-camp",
  CAR_ICON: "he-icon he-icon-car",
  CATTLE_ICON: "he-icon he-icon-cattle",
  CAUTION_ICON: "he-icon he-icon-caution",
  COLLAPSE_ICON: "he-icon he-icon-collapse",
  CONFIRMATION_ICON: "he-icon he-icon-confirmation",
  CONSTRUCTION_ICON: "he-icon he-icon-construction",
  DAMAGED_ICON: "he-icon he-icon-damaged",
  EVACUATION_AREA_ICON: "he-icon he-icon-evacuation-area",
  FARM_ICON: "he-icon he-icon-farm",
  FERRY_ICON: "he-icon he-icon-ferry",
  FIRE_BRIGADE_STATION_ICON: "he-icon he-icon-fire-brigade-station",
  FIRE_CISTERN_ICON: "he-icon he-icon-fire-cistern",
  FIRE_EXTINGUISHER_ICON: "he-icon he-icon-fire-extinguisher",
  FIRE_HYDRANT_ICON: "he-icon he-icon-fire-hydrant",
  FIRE_ICON: "he-icon he-icon-fire",
  FLOOD_ICON: "he-icon he-icon-flood",
  GAS_CUTOFF_ICON: "he-icon he-icon-gas-cutoff",
  GAS_STATION_ICON: "he-icon he-icon-gas-station",
  HERITAGE_ICON: "he-icon he-icon-heritage",
  HOSPITAL_ICON: "he-icon he-icon-hospital",
  HOTEL_ICON: "he-icon he-icon-hotel",
  INFORMATION_ICON: "he-icon he-icon-information",
  LANDSLIDE_ICON: "he-icon he-icon-landslide",
  LEAKAGE_ICON: "he-icon he-icon-leakage",
  MARKER_PLUS_ICON: "he-icon he-icon-marker-plus",
  MISSING_ICON: "he-icon he-icon-missing",
  MUSEUM_ICON: "he-icon he-icon-museum",
  NATURAL_WATER_ICON: "he-icon he-icon-natural-water",
  PARKING_ICON: "he-icon he-icon-parking",
  PARK_ICON: "he-icon he-icon-park",
  POLICE_ICON: "he-icon he-icon-police",
  POST_OFFICE_ICON: "he-icon he-icon-post-office",
  RESTAURANT_ICON: "he-icon he-icon-restaurant",
  RETIREMENT_HOME_ICON: "he-icon he-icon-retirement-home",
  RICE_ICON: "he-icon he-icon-rice",
  SCHOOL_ICON: "he-icon he-icon-school",
  SHELTER_ICON: "he-icon he-icon-shelter",
  SHOPPING_ICON: "he-icon he-icon-shopping",
  SHRINE_ICON: "he-icon he-icon-shrine",
  SMOKING_AREA_ICON: "he-icon he-icon-smoking-area",
  SPA_ICON: "he-icon he-icon-spa",
  STATION_ICON: "he-icon he-icon-station",
  STREAM_ICON: "he-icon he-icon-stream",
  TAXI_ICON: "he-icon he-icon-taxi",
  TEMPLE_ICON: "he-icon he-icon-temple",
  TOILET_ICON: "he-icon he-icon-toilet",
  TRAFFIC_ACCIDENT_ICON: "he-icon he-icon-traffic-accident",
  VACANT_HOUSE_ICON: "he-icon he-icon-vacant-house",
  WATER_CUTOFF_ICON: "he-icon he-icon-water-cutoff",
  WIFI_ICON: "he-icon he-icon-wifi",
  ZOO_ICON: "he-icon he-icon-zoo",
} as const;
