import { Auth } from "aws-amplify";
import { Configuration } from "../generated/configuration";

type ApiMode = "mock" | "backend" | "proxy" | undefined;
type Options = Partial<{
  noAuth: boolean;
}>;

export const createConfig = async (options?: Options) => {
  const mode = process.env.REACT_APP_API_MODE as ApiMode;
  const { noAuth = false } = options || {};

  const baseUrl =
    mode === "proxy"
      ? "/api/v1"
      : mode === "mock"
      ? process.env.REACT_APP_API_MOCK_URL || ""
      : process.env.REACT_APP_API_BASE_URL || "";
  const idToken = !noAuth ? await getIdToken() : undefined;
  const config = new Configuration({
    accessToken: idToken?.getJwtToken(),
    basePath: baseUrl,
  });
  return config;
};

const getIdToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken();
};
