import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
} from "react";
import { useAsync } from "react-use";
import { MyUserResponse } from "../../generated";
import { getCurrentUser, login, logout } from "../../repositories/auth";
import { createUser, fetchCurrentUser } from "../../repositories/user";

type AuthenticatedUserState = MyUserResponse | undefined;
type LoginProps = { password: string; username: string };
type SignUpProps = {
  displayName?: string;
  email: string;
  password: string;
  username: string;
};
type AuthFunctionsType = {
  login: (props: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  signUp: (props: SignUpProps) => Promise<void>;
  sync: () => Promise<void>;
};
const UserStateContext = createContext<AuthenticatedUserState | null>(null);
const AuthFunctionsContext = createContext<AuthFunctionsType | null>(null);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<AuthenticatedUserState>();
  const [isCompleted, setCompleted] = useState<boolean>(false);

  // ログインユーザー情報の同期を取る
  const sync = useCallback(async () => {
    const cognitoUser = await getCurrentUser();
    if (!cognitoUser) {
      setUser(undefined);
      return;
    }

    const res = await fetchCurrentUser();
    if (res.data) {
      setUser(res.data);
    }
  }, []);

  // login challenge
  useAsync(async () => {
    await sync().finally(() => setCompleted(true));
  }, [sync]);

  const wrappedLogin: AuthFunctionsType["login"] = useCallback(
    async (props) => {
      const { username, password } = props;
      await login(username, password);
      await sync();
    },
    [sync]
  );

  const wrappedLogout: AuthFunctionsType["logout"] = useCallback(async () => {
    await logout();
    await sync();
  }, [sync]);

  const wrappedSignUp: AuthFunctionsType["signUp"] = useCallback(
    async (props) => {
      const { username, password, email, displayName } = props;
      await createUser(username, password, email, displayName);
      await login(username, password);
      await sync();
    },
    [sync]
  );

  return (
    <UserStateContext.Provider value={user}>
      <AuthFunctionsContext.Provider
        value={{
          login: wrappedLogin,
          logout: wrappedLogout,
          signUp: wrappedSignUp,
          sync,
        }}
      >
        {isCompleted && children}
      </AuthFunctionsContext.Provider>
    </UserStateContext.Provider>
  );
};

const useAuth = (): [AuthenticatedUserState, AuthFunctionsType] => {
  const user = useContext(UserStateContext);
  const auth = useContext(AuthFunctionsContext);
  if (user === null || auth === null) {
    throw new Error(
      "No context provided: useAuth() can only be used within a AuthProvider"
    );
  }
  return [user, auth];
};

export { AuthProvider, useAuth };
