import { useCallback, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";
import {
  OrganizationsResponse,
  Organization,
  OrganizationMembersResponse,
  TeamsResponse,
  User,
} from "../../generated/api";
import {
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationMembers,
  fetchTeamsFromOrganizationMember,
} from "../../repositories/organization";
import {
  FetchHookMutate,
  FetchHookReturnValue,
  FetchHookSWROptions,
} from "../../types/hooks";

type FetchOrganizationsQueryParams = {
  page?: number;
  size?: number;
  sort?: string;
};

type UseFetchOrganizations = (
  params?: FetchOrganizationsQueryParams,
  options?: FetchHookSWROptions<OrganizationsResponse>,
  enable?: boolean
) => FetchHookReturnValue<OrganizationsResponse>;

export const useFetchOrganizations: UseFetchOrganizations = (
  params,
  options,
  enable = true
) => {
  const { mutate } = useSWRConfig();
  const { page, size, sort } = params || {};
  const key = useMemo(
    () => ["fetchOrganizations", page, size, sort],
    [page, size, sort]
  );
  const mutateWithKey: FetchHookMutate<OrganizationsResponse> = useCallback(
    (...args) => mutate(key, ...args),
    [key, mutate]
  );

  const swrResponse = useSWR(
    enable ? key : null,
    () => fetchOrganizations(page, size, sort),
    options
  );

  return { ...swrResponse, mutate: mutateWithKey };
};

type FetchOrganizationQueryParams = Record<string, never>;

type UseFetchOrganization = (
  organizationId: Organization["id"] | undefined,
  params?: FetchOrganizationQueryParams,
  options?: FetchHookSWROptions<Organization>,
  enable?: boolean
) => FetchHookReturnValue<Organization>;

export const useFetchOrganization: UseFetchOrganization = (
  organizationId,
  params,
  options,
  enable = true
) => {
  const { mutate } = useSWRConfig();
  const key = useMemo(
    () => (organizationId ? ["fetchOrganization", organizationId] : null),
    [organizationId]
  );
  const mutateWithKey: FetchHookMutate<Organization> = useCallback(
    (...args) => mutate(key, ...args),
    [key, mutate]
  );

  const swrResponse = useSWR(
    enable ? key : null,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchOrganization(organizationId!),
    options
  );

  return { ...swrResponse, mutate: mutateWithKey };
};

type FetchOrganizationMembersQueryParams = {
  name?: string;
  page?: number;
  size?: number;
  sort?: string;
};

type UseFetchOrganizationMembersType = (
  organizationId: Organization["id"] | undefined,
  params?: FetchOrganizationMembersQueryParams,
  options?: FetchHookSWROptions<OrganizationMembersResponse>,
  enable?: boolean
) => FetchHookReturnValue<OrganizationMembersResponse>;

export const useFetchOrganizationMembers: UseFetchOrganizationMembersType = (
  organizationId,
  params,
  options,
  enable = true
) => {
  const { mutate } = useSWRConfig();
  const { page, size, sort, name } = params || {};
  const key = useMemo(
    () =>
      !organizationId
        ? null
        : ["fetchOrganizationMembers", organizationId, page, size, sort, name],
    [organizationId, page, size, sort, name]
  );

  const mutateWithKey: FetchHookMutate<OrganizationMembersResponse> =
    useCallback((...args) => mutate(key, ...args), [key, mutate]);

  const swrResponse = useSWR(
    enable ? key : null,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchOrganizationMembers(organizationId!, page, size, sort, name),
    options
  );

  return { ...swrResponse, mutate: mutateWithKey };
};

type FetchTeamsFromOrganizationMemberQueryParams = {
  page?: number;
  size?: number;
  sort?: string;
};

type UseFetchTeamsFromOrganizationMemberType = (
  organizationId: Organization["id"] | undefined,
  userId: User["id"] | undefined,
  params?: FetchTeamsFromOrganizationMemberQueryParams,
  options?: FetchHookSWROptions<TeamsResponse>,
  enable?: boolean
) => FetchHookReturnValue<TeamsResponse>;

export const useFetchTeamsFromOrganizationMember: UseFetchTeamsFromOrganizationMemberType =
  (organizationId, userId, params, options, enable = true) => {
    const { mutate } = useSWRConfig();
    const { page, size, sort } = params || {};
    const key = useMemo(
      () =>
        !organizationId || !userId
          ? null
          : [
              "fetchTeamsFromOrganizationMember",
              organizationId,
              userId,
              page,
              size,
              sort,
            ],
      [organizationId, page, size, sort, userId]
    );

    const mutateWithKey: FetchHookMutate<TeamsResponse> = useCallback(
      (...args) => mutate(key, ...args),
      [key, mutate]
    );

    const swrResponse = useSWR(
      enable ? key : null,
      () =>
        fetchTeamsFromOrganizationMember(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          organizationId!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userId!,
          page,
          size,
          sort
        ),
      options
    );

    return { ...swrResponse, mutate: mutateWithKey };
  };
