import { FC, createContext, useContext, ProviderProps } from "react";
import { Organization } from "../../generated/api";

const OrganizationContext = createContext<Organization | null>(null);

const OrganizationProvider: FC<ProviderProps<Organization>> = ({
  value,
  children,
}) => {
  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

const useOrganization = (): Organization => {
  const organization = useContext(OrganizationContext);
  if (organization === null) {
    throw new Error(
      "No context provided: useOrganization() can only be used within a OrganizationProvider"
    );
  }
  return organization;
};

export { OrganizationProvider, useOrganization };
