import { Amplify } from "aws-amplify";
import clsx from "clsx";
import { Suspense, useEffect, useMemo } from "react";
import { matchPath, useLocation, useRoutes } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { HeaderMenu } from "./components/menus/headerMenu/HeaderMenu";
import { ToastPortal } from "./components/toast/ToastPortal";
import { cognitoConfig } from "./constants/AwsConfig";
import { RoutePaths } from "./constants/RoutePaths";
import { AuthProvider } from "./hooks/contexts/AuthContext";
import { CurrentOrganizationProvider } from "./hooks/contexts/CurrentOrganizationContext";
import { ToastProvider } from "./hooks/contexts/ToastContext";
import { routes } from "./routes";
import styles from "./App.module.scss";

const routesWithoutHeader = [
  RoutePaths.LOGIN,
  RoutePaths.SIGN_UP,
  RoutePaths.MAP,
];

function App() {
  Amplify.configure(cognitoConfig);

  const { pathname } = useLocation();
  const showHeaderMenu = useMemo(
    () =>
      routesWithoutHeader.every((path) => matchPath(path, pathname) === null),
    [pathname]
  );

  // モバイル用にアドレスバー、ツールバーを除いたvhを計算
  // var(--vh, 1vh) で使用可能
  useEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const element = useRoutes(routes);

  return (
    <div className={styles.root}>
      <ToastProvider>
        <AuthProvider>
          <CurrentOrganizationProvider>
            {showHeaderMenu && <HeaderMenu />}
            <div className={clsx({ [styles.withHeader]: showHeaderMenu })}>
              <Suspense
                fallback={
                  <Dimmer active page inverted>
                    <Loader active>Loading...</Loader>
                  </Dimmer>
                }
              >
                {element}
              </Suspense>
            </div>
            <ToastPortal />
          </CurrentOrganizationProvider>
        </AuthProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
