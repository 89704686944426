import { useUser } from "../../hooks/contexts/UserContext";

type Props = {
  className?: string;
};

export const UserName = (props: Props) => {
  const { className } = props;
  const user = useUser();

  return (
    <span className={className}>{user.displayName || user.name || ""}</span>
  );
};
