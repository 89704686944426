import { createContext, useContext, ProviderProps } from "react";
import { User, OrganizationMember, MyUserResponse } from "../../generated/api";

type UserContextValue = User | OrganizationMember | MyUserResponse;

const UserContext = createContext<UserContextValue | null>(null);

const UserProvider = ({ value, children }: ProviderProps<UserContextValue>) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = (): UserContextValue => {
  const user = useContext(UserContext);
  if (user === null) {
    throw new Error(
      "No context provided: useUser() can only be used within a UserProvider"
    );
  }
  return user;
};

export { UserProvider, useUser };
