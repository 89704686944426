/* eslint-disable sort-keys-fix/sort-keys-fix */

import relativePath from "get-relative-path";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router";
import { PrivatePage } from "./components/router/PrivatePage";
import { RoutePaths } from "./constants/RoutePaths";
import { MobileView, NonMobileView } from "./hooks/lib/useIsMobile";

const ChangePasswordPage = lazy(
  () => import("./pages/_old/ChangePasswordPage")
);
const LoginPage = lazy(() => import("./pages/LoginPage"));
const LogoutPage = lazy(() => import("./pages/LogoutPage"));
const MapPage = lazy(() => import("./pages/MapPage"));
const MapPageForMobile = lazy(() => import("./pages/mobile/MapPageForMobile"));
const MyPage = lazy(() => import("./pages/MyPage"));
const MyPageOld = lazy(() => import("./pages/_old/MyPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const OrganizationOldPage = lazy(() => import("./pages/_old/OrganizationPage"));
const SignUpPage = lazy(() => import("./pages/SignUpPage"));
const TeamPage = lazy(() => import("./pages/_old/TeamPage"));
const UserNameEditPage = lazy(() => import("./pages/_old/UserNameEditPage"));
const UserProfileEditPage = lazy(
  () => import("./pages/_old/UserProfileEditPage")
);
const MyPageSample = lazy(() => import("./pages/_sample/MyPageSample"));
const OrganizationSamplePage = lazy(
  () => import("./pages/_sample/OrganizationSamplePage")
);
const OrganizationPage = lazy(() => import("./pages/OrganizationPage"));

// MEMO: 自動ソートを無効化しているため、path -> element -> children の順で書く
export const routes: RouteObject[] = [
  { path: RoutePaths.LOGIN, element: <LoginPage /> },
  { path: RoutePaths.LOGOUT, element: <LogoutPage /> },
  { path: RoutePaths.SIGN_UP, element: <SignUpPage /> },
  {
    path: "/*",
    element: <PrivatePage />,
    children: [
      {
        element: (
          <>
            <NonMobileView>
              <MapPage />
            </NonMobileView>
            <MobileView>
              <MapPageForMobile />
            </MobileView>
          </>
        ),
        path: relativePath("/", RoutePaths.MAP),
      },
      {
        element: <UserProfileEditPage />,
        path: relativePath("/", RoutePaths.PROFILE_EDIT_OLD),
      },
      {
        element: <UserNameEditPage />,
        path: relativePath("/", RoutePaths.USER_NAME_EDIT_OLD),
      },
      {
        element: <ChangePasswordPage />,
        path: relativePath("/", RoutePaths.CHANGE_PASSWORD_OLD),
      },
      {
        element: <MyPage />,
        path: relativePath("/", RoutePaths.MY_PAGE),
      },
      {
        element: <MyPageOld />,
        path: relativePath("/", RoutePaths.MY_PAGE_OLD),
      },
      {
        element: <MyPageSample />,
        path: relativePath("/", RoutePaths.MY_PAGE_SAMPLE),
      },
      {
        element: <OrganizationOldPage />,
        path: relativePath("/", RoutePaths.ORGANIZATION_OLD),
      },
      {
        element: <OrganizationSamplePage />,
        path: relativePath("/", RoutePaths.ORGANIZATION_SAMPLE),
      },
      {
        element: <TeamPage />,
        path: relativePath("/", RoutePaths.TEAM_OLD),
      },
      {
        element: <OrganizationPage />,
        path: relativePath("/", RoutePaths.ORGANIZATION),
      },
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  { path: RoutePaths.ROOT, element: <Navigate replace to={RoutePaths.MAP} /> },
];
