import { useCurrentOrganizationState } from "../../hooks/contexts/CurrentOrganizationContext";
import { OrganizationProvider } from "../../hooks/contexts/OrganizationContext";
import { OrganizationName } from "./OrganizationName";

type Props = {
  className?: string;
};

export const CurrentOrganizationName = (props: Props) => {
  const { className } = props;
  const organization = useCurrentOrganizationState();

  if (!organization) return null;

  return (
    <OrganizationProvider value={organization}>
      <OrganizationName className={className} />
    </OrganizationProvider>
  );
};
