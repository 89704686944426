import { ReactNode, useEffect, useMemo, useState } from "react";
import { isMobileOnly } from "react-device-detect";

export const useIsMobile = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const isMobile = useMemo(() => {
    return isMobileOnly || width <= 450;
  }, [width]);

  return isMobile;
};

export const MobileView = ({ children }: { children?: ReactNode }) => {
  const isMobile = useIsMobile();

  return <>{isMobile ? children : null}</>;
};

export const NonMobileView = ({ children }: { children?: ReactNode }) => {
  const isMobile = useIsMobile();

  return <>{!isMobile ? children : null}</>;
};
